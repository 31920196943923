import React, {useState} from 'react';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';

type DraggableClickableProps = {
    children: React.ReactNode;
    onClick: () => void;
};

const DraggableClickable: React.FC<DraggableClickableProps> = ({ children, onClick }) => {
    const [dragging, setDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    const handleStart = (_de: DraggableEvent, dd: DraggableData) => {
        setStartPosition({ x: dd.x, y: dd.y });
        setDragging(false);
    };

    const handleDrag = (_de: DraggableEvent, dd: DraggableData) => {
        const distance = Math.sqrt(Math.pow(dd.x - startPosition.x, 2) + Math.pow(dd.y - startPosition.y, 2));
        if (distance > 10) {
            setDragging(true);
        }
    };

    function handleStop() {
        if (!dragging) {
            onClick?.();
        }
    }

    return (
        <Draggable onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
            {children}
        </Draggable>
    );
};

export default DraggableClickable;
