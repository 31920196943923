import {Alert} from "@mui/material";
import styles from "./styles.module.css";
import Button from "@mui/material/Button";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import UndoIcon from "@mui/icons-material/Undo";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import { I18N } from "../../../utils/i18n";

export type PromptProps = {
    oldPassword: string;
    newPassword: string
    onRevert?: () => void;
    onStart?: () => void;
    onIgnore?: () => void;
}

const PromptConversionStartDialog = (props: PromptProps) => {
    if (props.oldPassword === props.newPassword) {
        return (<> </>)
    }
    return (
        <Alert className={styles.convert}
               severity={"warning"}
        >
            <div>
                {(!props.oldPassword && props.newPassword) && <>
                    <p><I18N>
                        EquiQuill always uses a randomly generated secure password to protect your data.
                        This password is stored in a dedicated secure area of Google's cloud infrastructure,
                        which, in addition to providing state-of-the-art security, also systematically generates
                        access logs that are monitored and audited.
                    </I18N></p>
                    <p><I18N>
                        Only set a password if you prefer having <b>exclusive</b> control over your security settings.
                    </I18N></p>
                    <p><I18N>
                        Please be aware that managing your own password carries responsibility. Anytime you
                        log in on a new browser or device, you will need to go to that device's settings and enter
                        the password before you can access your journal entries from that device. Anytime your
                        browser's cache is cleared, you will need to re-enter the password. <b>If you forget your
                        password, you will permanently lose access to your data, as we will not be able to recover
                        your data</b>.
                    </I18N></p>
                    <p><I18N>
                        You have set a new password. Data conversion is needed for the changes to take effect.
                    </I18N></p>
                </>}
                {props.oldPassword && props.newPassword &&
                    <p><I18N>
                        You have changed your password. Data conversion is needed for the changes to take effect.
                    </I18N></p>
                }
                {props.oldPassword && !props.newPassword &&
                    <p><I18N>
                        You have removed your password. Data conversion is needed for the changes to take effect.
                    </I18N></p>
                }
                <div className={styles.actions}>
                    {props.onIgnore && (<Button color="inherit" size="small" startIcon={<ErrorIcon/>}
                                                onClick={props.onIgnore}>
                        <I18N>Ignore</I18N>
                    </Button>)}
                    {props.onRevert && (<Button color="inherit" size="small" startIcon={<UndoIcon/>}
                                                onClick={props.onRevert}>
                        <I18N>Revert</I18N>
                    </Button>)}

                    {props.onStart && (<Button color="inherit" size="small" startIcon={<PlayCircleOutlineIcon/>}
                                               onClick={props.onStart}>
                        <I18N>Convert Data</I18N>
                    </Button>)}
                </div>
            </div>
        </Alert>
    )
}

export default PromptConversionStartDialog;