import React from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";
import styles from './styles.module.css'
import {CharacterEditor} from "../charactereditor";
import {Character} from "../../../../shared/types";
import {SimpleI18NMessage} from "../../../../utils/i18n";

export type CharacterFormProperties = {
    readonly characters: Character[],
    readonly onCharactersChanged: (newCharacters: Character[]) => void,
}

const CharacterForm: React.FC<CharacterFormProperties> = (props: CharacterFormProperties) => {

    function addCharacter() {
        const newCharacter : Character = {
            name: '',
            description: '',
        }
        props.onCharactersChanged([...props.characters, newCharacter]);
    }

    function characterChanged(id: number, character: Character) {
        const newCharacters = [...props.characters];
        newCharacters[id] = character;
        props.onCharactersChanged(newCharacters);
    }

    function deleteRow(number: number) {
        const newCharacters = [...props.characters];
        newCharacters.splice(number, 1);
        props.onCharactersChanged(newCharacters);
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" className={styles.charactersTable}>
                <TableHead>
                    <TableRow>
                        <TableCell><SimpleI18NMessage msg={"Name"}/></TableCell>
                        <TableCell><SimpleI18NMessage msg={"Description"}/></TableCell>
                        <TableCell align="right"><SimpleI18NMessage msg={"Actions"}/></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.characters.map((c,idx) => (
                            <
                                CharacterEditor key={'character-' + idx} id={idx} character={c}
                                             onCharacterChanged={characterChanged}
                                            onCharacterDeleted={deleteRow}
                            />
                        ))
                    }
                    <TableRow>
                        <TableCell colSpan={6} align="right">
                            <IconButton>
                                <AddBoxIcon onClick={addCharacter} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

};

export default CharacterForm;
