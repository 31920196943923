import React, {useContext, useEffect, useState} from "react";
import Joyride, {CallBackProps, Step} from "react-joyride";
import {useLocation} from "react-router-dom";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {I18N, useSimpleI18n} from "../../utils/i18n";

export const TUT_1_CLASSNAME = 'tutorial1'
export const TUT_2_CLASSNAME = 'tutorial2'
export const TUT_3_CLASSNAME = 'tutorial3'
export const TUT_4_CLASSNAME = 'tutorial4'
export const TUT_5_CLASSNAME = 'tutorial5'
export const TUT_6_CLASSNAME = 'tutorial6'
export const TUT_7_CLASSNAME = 'tutorial7'


type JrProps = {
    idx: number,
    mainRoute: string
}

const STEPS_RUN = [false, false, false]



const JoyRideN = (Props: JrProps) => {
    const {idx, mainRoute} = Props;
    const [run, setRun] = useState(false);
    const esApi = useContext(EsApiContext);
    const location = useLocation()
    const i18n = useSimpleI18n()

    async function markComplete(data: CallBackProps, idx: number) {
        const settings = await esApi.getSettings();
        if (data.action === 'reset') {
            if (!settings.tutorialsCompleted) {
                settings.tutorialsCompleted = []
            }
            settings.tutorialsCompleted.push(idx);
            // noinspection ES6MissingAwait
            esApi.updateSettings(settings);
        }
        if (data.action === 'reset') {
            console.log(`Tutorial ${idx}: Marking as complete`)
            localStorage.setItem('tutorial-' + idx, 'true');
        }
    }

    const TUT_STEPS: Step[][] =
        [
            [
                {
                    target: '.' + TUT_1_CLASSNAME,
                    content: i18n('Welcome to EquiQuill. When on the home page, you will see a depiction of your journal entries.'),
                    placement: 'center',
                    disableBeacon: true,
                },
                {
                    target: '.' + TUT_2_CLASSNAME,
                    content: i18n("You can click the '+' button to add a new journal entry."),
                    disableBeacon: true,
                    disableScrolling: true,
                }
            ], [
            {
                target: '.' + TUT_3_CLASSNAME,
                content: (<>
                        <div>
                            <I18N>Start a new journal entry by entering a few words. If you're not sure what to write, write
                                about what's happened to you so far today, and what you see around you.</I18N>
                        </div>
                        <br/>
                        <div>
                            <I18N>EquiQuill needs <b>at least 100 characters</b> before it can do its magic.</I18N>
                        </div>
                    </>
                ),
                disableBeacon: true,
            },
            {
                target: '.' + TUT_4_CLASSNAME,
                content: i18n("If you have a stylus and prefer journaling by hand, you can click the 'pencil' button to toggle between keyboard and stylus."),
                disableBeacon: true,
            },
            {
                target: '.' + TUT_5_CLASSNAME,
                content: i18n("Once you've written a few words, EquiQuill will go through a series of steps and do its magic."),
                disableBeacon: true,
            },

        ],
            [
                {
                    target: '.' + TUT_6_CLASSNAME,
                    content: i18n(`Once complete, you will se an image that represents your journal entry, as
                      well as a short AI-generated psychological assessment of your entry.
                      `),
                    disableBeacon: true,
                    placement: 'center',
                    styles: {
                        options: {
                            zIndex: 1400
                        }
                    },
                },
                {
                    target: '.' + TUT_7_CLASSNAME,
                    content: i18n('If the people in the image don\'t match your world, you can go to settings→characters to describe yourself and your friends.'),
                    disableBeacon: true,
                    styles: {
                        options: {
                            zIndex: 1400
                        }
                    },
                }
            ]
        ];



    useEffect(() => {
        if (STEPS_RUN[idx]) {
            return;
        }
        STEPS_RUN[idx] = true;
        async function hasRun(idx: number) {
            return (await esApi.getSettings()).tutorialsCompleted?.includes(idx)
        }

        (async () => {
            while (true) {
                if (!esApi.isLoggedIn) {
                    console.log(`Tutorial ${idx}: Waiting for login`);
                    // do nothing, wait for log in
                } else if ( (await hasRun(idx)) ) {
                    console.log(`Tutorial ${idx}: Tutorial has already run.  Stopping`);
                    return;
                } else if (idx > 0 && !await hasRun(idx - 1)) {
                    console.log(`Tutorial ${idx}: Waiting for tutorial ${idx - 1} to complete`)
                } else {
                    const selector = TUT_STEPS[idx][0].target as string;
                    const el = document.querySelector(selector);
                    console.log(`Tutorial ${idx}: Waiting for ${selector} ${el}`)
                    if (el) {
                        break;
                    }
                }
                await new Promise(r => setTimeout(r, 1000))
            }
            setRun(true);
        })()
    }, [TUT_STEPS, esApi, idx, location.pathname, mainRoute]);

    // wait to be logged in and for location to
    return (
        <Joyride run={run}
                 steps={TUT_STEPS[idx]}
                 continuous={true}
                 locale={
                    {
                        last: i18n('Close'),
                        next: i18n('Next'),
                        back: i18n('Back'),
                    }
                 }
                 callback={(data) => markComplete(data, idx)}
        />
    )
}

export const JoyRide = () => {
    return (
        <>
            <JoyRideN key='jr1' idx={0} mainRoute={'escal'}/>
            <JoyRideN key='jr2' idx={1} mainRoute={'dayview/20'}/>
            <JoyRideN key='jr3' idx={2} mainRoute={'dayview/20'}/>
        </>
    )
}
