import React, {useState} from "react";
import ESInfiniView from "../esinfiniview";
import ESCal from "../escal";
import styles from './styles.module.css';
import IconButton from "@mui/material/IconButton";
import {useSimpleI18n} from "../../utils/i18n";
import DraggableClickable from "../draggableclickable";
import {Fab} from "@mui/material";

const DayOrMonthView = () => {
    const [isDayView, setIsDayView] = useState(true);

    const i18n = useSimpleI18n();

    return (
        <div className={styles.main}>
            {isDayView && (
                <DraggableClickable onClick={() => setIsDayView(false)}>
                <IconButton title={i18n("Month View")}
                            style={{right: '40px'}}
                >
                    <img src='/MonthView.png' style={{width: '32px', height: '32px'}} draggable={false}/>
                </IconButton>
                </DraggableClickable>
            )}
            {!isDayView && (
                <DraggableClickable onClick={() => setIsDayView(true)}>
                    <Fab>
                        <IconButton title={i18n("Day View")} style={{right: '16px'}}>
                            <img src='/DayView.png' style={{width: '32px', height: '32px'}} draggable={false}/>
                        </IconButton>
                    </Fab>
                </DraggableClickable>
            )}
            <div style={{position: 'relative'}}>
            {isDayView && <ESInfiniView/>}
            {!isDayView && <ESCal/>}
            </div>
        </div>
    )

}

export default DayOrMonthView;