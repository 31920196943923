import React, {useContext} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from './styles.module.css'
import {AutocompleteChangeDetails, AutocompleteChangeReason} from "@mui/base/useAutocomplete/useAutocomplete";
import {Character} from "../../../../shared/types";
import {EsApiContext} from "../../../../utils/equi-scrib-internal-api-context";
import {useSimpleI18n} from "../../../../utils/i18n";

export type CharacterEditorProps = {
    id: number
    character: Character
    onCharacterChanged: (id: number, character: Character) => void
    onCharacterDeleted: (id: number) => void;
}

const SAMPLE_PROMPTS: string[] = [
    "A cheerful robot with bright blue eyes and a wide, friendly smile, sporting a shiny silver exterior",
    "An elderly wizard with a long, white beard, sparkling eyes, and a tall pointed hat adorned with stars",
    "A young girl with curly red hair, freckles, and a mischievous grin, wearing a green dress and sneakers",
    "A majestic dragon with shimmering scales, wise eyes, and large, powerful wings, exuding a sense of ancient wisdom",
    "A tall, athletic basketball player with a focused expression, short cropped hair, and wearing a red and white jersey",
    "A small, curious kitten with fluffy fur, wide, inquisitive eyes, and a playful stance",
    "A graceful ballerina with a slender build, poised elegantly on tiptoe, her hair tied in a neat bun",
    "A rugged cowboy with a weathered face, wide-brimmed hat, and a confident, relaxed posture",
    "A mysterious alien with glowing green skin, large, expressive eyes, and a slender, otherworldly physique",
    "A wise old owl with piercing eyes, ruffled feathers, and an air of intelligence, perched on a branch",
]

export const CharacterEditor: React.FC<CharacterEditorProps> = (props) => {


    const [c, setCharacter] = React.useState<Character>(props.character);

    const i18n = useSimpleI18n();

    const esApi = useContext(EsApiContext);
    const name = esApi.user.displayName ? (esApi.user.displayName.split(' ')[0] + ',') : ''

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        {
            setCharacter({
                ...c,
                [event.target.name]: event.target.value,
            });
        }
    }

    const commitChanges = (arg1: any) => {
        if (JSON.stringify(c) !== JSON.stringify(props.character))
            props.onCharacterChanged(props.id, c);
    }

    function deleteRow() {
        props.onCharacterDeleted(props.id);
    }

    function handleGenderChange(
        event: React.SyntheticEvent,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string>
    ) {

    }

    let placeholder = i18n(SAMPLE_PROMPTS[Math.floor(Math.random() * 10)]);


    function handlePotentialTab(placeHolder: string, e: React.KeyboardEvent<HTMLDivElement>) {
        // if the target component's field is empty, and the key is tab, then set the text to the placeholder value
        if (e.key === 'Tab') {
            // for some reason, we have a "blank" string that is a one char string with charCode 8203.  Consider
            // this blank too.
            const value = (e.target as any).value
            const name = (e.target as any).name
            if (!value) {
                e.preventDefault()
                setCharacter({
                    ...c,
                    [name]: placeHolder
                });
            }
        }
    }

    return (
        <TableRow id={'character-' + props.id} className={styles.tr} onBlur={commitChanges}>
            <TableCell component="th" scope="row">
                <TextField value={c.name}
                           name={'name'}
                           onChange={handleInputChange}
                           onKeyDown={(e) => {
                               if (props.id === 0) {
                                   handlePotentialTab(`${name}author,narrator`, e)
                               }
                           }}
                           variant={'outlined'}
                           size={"small"}
                           placeholder={props.id == 0 ? `${name}author,narrator` : 'John,Johnathon,Johnny,Johny,Jo'}
                           inputProps={{maxLength: 100}}
                />
            </TableCell>
            <TableCell component="th" scope="row">
                <TextField multiline={true}
                           value={c.description}
                           name={'description'}
                           onChange={handleInputChange}
                           onKeyDown={(e) => handlePotentialTab(placeholder, e)}
                           variant={'outlined'}
                           size={"small"}
                           fullWidth={true}
                           placeholder={placeholder}
                           inputProps={{maxLength: 250}}
                />
            </TableCell>
            <TableCell align="right">
                <IconButton onClick={deleteRow}>
                    <DeleteIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    );
}