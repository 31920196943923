// pages/login.js

import React, {useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";

const LogoutPage = () => {
    // clear cookie esAuth
    // and navigate to login page
    const esApi = useContext(EsApiContext);

    async function logout() {
        console.log("Logging out, Clearing cookie");
        document.cookie = "esAuth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        await esApi.logout();
        window.location.href = '/#/login'
    }

    useEffect(() => {
        logout();
    }, []);
    return (<div/>);
};

export default LogoutPage;
