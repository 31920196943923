import Markdown from "react-markdown";
import {useContext, useEffect, useState} from "react";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {VersionInfo} from "../../shared/types";


const PublicMdPage = () => {

    const [policy, setPolicy] = useState('Loading...');
    const [clientVersion, setClientVersion] = useState(undefined as (VersionInfo|undefined));
    const [serverVersion, setServerVersion] = useState(undefined as (VersionInfo|undefined));

    const esApi = useContext(EsApiContext)

    // load
    useEffect(() => {
        fetch('/about.md')
            .then((response) => response.text())
            .then((text) => {
                setPolicy(text);
            });

        fetch('/version.json')
            .then((response) => response.json())
            .then((version) => {
                setClientVersion(version)
            });
        esApi.getVersion().then((version) => {
            setServerVersion(version)
        });
    }, [esApi]);
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px', fontSize: 'small'}}>
                <div><span>Client Version: </span>{clientVersion?.version} / {clientVersion?.buildDate}</div>
                <div><span>API Version: </span>{serverVersion?.version} / {serverVersion?.buildDate}</div>
            </div>
            <Markdown key={'about.md'} className={'privacyPolicy'}>{policy}</Markdown>
        </div>
    )

}

export default PublicMdPage;