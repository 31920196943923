import Calendar, {TileArgs} from "react-calendar";
import {useContext, useEffect, useState} from "react";
import {DaySummary, seSmallImage} from "../../shared/types";
import {useNavigate} from "react-router-dom";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {useIntl} from "react-intl";

function convertDateToYYYYMMDD(day: Date): string {
    const year = day.getFullYear().toString();
    // Pad the month and day with a leading zero if necessary
    const month = (day.getMonth() + 1).toString().padStart(2, '0');
    const date = day.getDate().toString().padStart(2, '0');

    return `${year}${month}${date}`;
}



// Mock function that returns the number of entries for a day
const ESCal = () => {

    const [daySummaries, setDaySummaries] = useState([] as Array<DaySummary>);

    const esApi = useContext(EsApiContext)

    const navigate = useNavigate();

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        setCurrentMonth(new Date())
    }, [setCurrentMonth]);

    const intl = useIntl();

    // Custom tile content to show the number of entries
    const renderDayTile = (args: TileArgs) => {
        const daySummary = daySummaries[args.date.getDate()];
        const future = () => args.date.getTime() > Date.now();

        const at = [0];
        // if date greater than today, add 'future' to class names.
        let divImgClassName = 'react-calendar__esday' + (future() ? ' react-calendar__esday--future' : !(daySummary?.entries[0]?.imageUrl)? ' react-calendar__esday--empty' : '');

        return (
            // a simple div, with a background image that is the first entry for that day
            <div className={divImgClassName} style={{
                backgroundImage: (!future()) ? (`url(${
                    daySummary?.entries?.length > 0 ? 
                        seSmallImage(daySummary.entries[0].imageUrl) || 'https://storage.googleapis.com/equi-scrib-images/filledjournal.png' : ''})`
                ) : undefined,
            }}>
                <div className='react-calendar__esday_emoji'>
                {daySummary?.entries?.slice()?.reverse()?.map(
                    (entry) => {
                        const text = entry.summaryEmoji?.split(",") || [];
                        return <div key={at[0]++} title={text[0]?.trim()}>{text[1]?.trim()}</div>
                    }
                )}
                </div>
            </div>
        )
    };


    async function setCurrentMonth(activeStartDate: Date | null) {
        if (!activeStartDate) {
            setDaySummaries([])
            return;
        }
        // get a string as YYYYMM (e.g. 202101)
        const yearMonth: string = activeStartDate.getFullYear() + (activeStartDate.getMonth() + 1).toString().padStart(2, '0');
        const apiResult = await esApi.getMonthSummaries(yearMonth);
        setDaySummaries(apiResult)
    }

    return (
        <div>
            <Calendar
                tileContent={renderDayTile} // Use the custom tile content function
                onActiveStartDateChange={(date) => setCurrentMonth(date.activeStartDate)}
                showNeighboringMonth={false}
                maxDate={new Date()}
                minDate={new Date(2023, 0, 1)}
                locale={intl.locale}
                onClickDay={(day) => {
                    navigate("/dayview/" + convertDateToYYYYMMDD(day) + '.0')
            }} />
        </div>
    );
}

export default ESCal
