import Markdown from "react-markdown";
import {useContext, useEffect, useState} from "react";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";

export interface PublicMdPageProps {
    page: string
}

const PublicMdPage = (props: PublicMdPageProps) => {

    const [policy, setPolicy] = useState('Loading...');
    const esApi = useContext(EsApiContext);
    const urlToFetch = '/' + props.page
    useEffect(() => {
        esApi.fetchStatic(urlToFetch)
            .then((response) => response.data)
            .then((text) => {
                setPolicy(text);
            });
    }, [esApi, urlToFetch]);
    return (
        <Markdown key={props.page} className={'privacyPolicy'}>{policy}</Markdown>
    )

}

export default PublicMdPage;