// pages/login.js
import React, {useCallback, useContext, useEffect, useState} from 'react';
import 'cordova-plugin-purchase'
import styles from './styles.module.css'
import {I18N, SimpleI18NMessage, useSimpleI18n} from "../../utils/i18n";
import LoadingOverlay from "../loadingoverlay";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import PasswordStrengthBar from "react-password-strength-bar";
import {isEqual} from "lodash";
import {client_app, userIsVerified} from "../../shared/utils/firebase-client";
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    OAuthProvider,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    User
} from 'firebase/auth';
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {useLocation, useNavigate} from 'react-router-dom';
import {GoogleAuthProvider} from "@firebase/auth";
import SignInWithGoogle from './signinwithgoogle';
import SignInWithFacebook from './signinwithfacebook';
import {Capacitor} from "@capacitor/core";
import {FirebaseAuthentication} from "@capacitor-firebase/authentication";
import SignInWithApple from './signinwithapple';

const _APPLE_PROVIDER = new OAuthProvider('apple.com');


interface FirebaseOrCapacitorFirebaseUser {
    getIdToken: (forceRefresh: boolean) => Promise<string>
    email?: string|null|undefined
}

const LoginPage = () => {
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [user, setUser] = useState(undefined as FirebaseOrCapacitorFirebaseUser | undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const [waitingPasswordReset, setWaitingPasswordReset] = useState(false);
    const esApi = useContext(EsApiContext)
    const auth = React.useRef(undefined as any);
    const [displayVerifyEMailDialog, setDisplayVerifyEMailDialog] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [alreadyUsedEmailError, setAlreadyUsedEmailError] = useState('');


    const [variant, setVariant] = useState('in');

    const i18n = useSimpleI18n();
    const location = useLocation();
    const navigate = useNavigate();

    const [formState, setFormState] = useState({
        values: {email: '', password1: '', password2: '', remember: false},
        errors: {} as any, warnings: {} as any, focused: {} as any
    });

    useEffect(() => {
        // check if signup=true is set as a query parameter
        if (location.search.toLowerCase().includes('signup=true')) {
            setVariant('up');
        }
    }, []);

    const [passwordStrength, setPasswordStrength] = useState(0);

    function clearErrors() {
        const newState = {
            ...formState,
            errors: {} as any,
            warnings: {} as any
        };
        return newState;
    }

    const checkErrors = useCallback((isFinal: boolean) => {
        const newState = clearErrors();
        newState.errors.email = !testEmail(formState.values.email) ? i18n('Invalid email') : '';
        if (alreadyUsedEmailError === formState.values.email && formState.values.email) {
            newState.errors.email = i18n('This email is already registered.  Please try logging in via the "Already have an account?" link, below.')
        }
        if (variant !== 'up' && passwordStrength < 1) {
            newState.errors.password1 = i18n('Please specify a password');
        } else if (variant !== 'up' && passwordStrength < 2) {
            newState.errors.password1 = i18n('Please specify a password that matches EquiQuill\'s password requirements');
        }
        if (variant === 'up' && passwordStrength < 2) {
            newState.errors.password1 = i18n('Password is too weak.  We can\'t in all good conscience let you use this password.  Please find a better one')
        } else if (variant === 'up' && passwordStrength < 3) {
            newState.warnings.password1 = i18n('Who are we to tell you what password you can or cannot use? But we will tell you one thing: this password is weak.  Maybe you could find a better one?')
        }
        if (variant === 'up' && formState.values.password1 !== formState.values.password2) {
            newState.errors.password2 = i18n('Passwords do not match');
        }
        const hasErrors = !!(newState.errors.email || newState.errors.password1 || newState.errors.password2);
        setCanSubmit(!hasErrors);
        // remove errors for fields that haven't been focused yet.
        if (!isFinal) {
            for (const key in newState.errors) {
                if (!newState.focused[key]) {
                    newState.errors[key] = '';
                }
            }
        }
        for (const key in newState.errors) {
            if (!newState.focused[key]) {
                newState.errors[key] = '';
            }
        }
        if (!isEqual(formState, newState)) {
            setFormState(newState);
        }
        console.log("Checked for errors, found errors is " + hasErrors)
        return hasErrors
    }, [variant, formState, i18n, alreadyUsedEmailError, passwordStrength]);

    useEffect(() => {
        checkErrors(false);
    }, [checkErrors, formState, passwordStrength, variant]);


    useEffect(() => {
        async function pollEMailVerificationStatus() {
            setTimeout(async () => {
                if (!auth.current.currentUser) {
                    return;
                }
                await auth.current.currentUser.reload();
                if (!auth.current.currentUser) {
                    return;
                }
                if (userIsVerified(auth.current.currentUser)) {
                    setUser(auth.current.currentUser);
                    setDisplayVerifyEMailDialog(false)
                } else {
                    // noinspection ES6MissingAwait
                    pollEMailVerificationStatus();
                }
            }, 2000);
        }

        auth.current = getAuth(client_app);
        const unsubscribe = auth.current.onAuthStateChanged(async (user: any) => {
            console.log("Auth state has changed (user verified == " + userIsVerified(user) + ")");
            if (user) {
                setUser(user)
                if (!userIsVerified(user)) {
                    setDisplayVerifyEMailDialog(true);
                    // noinspection ES6MissingAwait
                    pollEMailVerificationStatus();
                }
            }
            setUser(user);
        });
        return () => {
            unsubscribe();
        }
    }, []);


    useEffect(() => {
        async function finalizeLogin() {
            console.log("Finalizing login")
            esApi.esAuth = await (user!.getIdToken(false))
            console.log(`Finalized login.  esAuth is ${esApi.esAuth}`);
            await esApi.login()
        }

        console.log(`User is ${user}, displayVerifyEMailDialog is ${displayVerifyEMailDialog} and userIsVerified is ${userIsVerified(user)}`);
        if (user && userIsVerified(user) && !displayVerifyEMailDialog) {
            // at this point, we should have a verified user, with an up-to-date access token.
            // login to our own API using this token, which will give us an esAuth cookie
            finalizeLogin().then(() => {
                navigate('/escal')
            });
        }
    }, [user, displayVerifyEMailDialog, navigate, esApi]);

    // if user is signing in, return a black screen
    if (isSigningIn) {
        return (<LoadingOverlay/>)
    }



    async function doSignIn(provider: GoogleAuthProvider|FacebookAuthProvider|OAuthProvider) {
        provider.addScope('email')
        setIsSigningIn(true);
        try {
            if (Capacitor.isNativePlatform()) {
                let signInResult;
                if (provider.providerId === 'facebook.com') {
                    console.log("Native platform... signing-in with Facebook");
                    signInResult = (await FirebaseAuthentication.signInWithFacebook());
                } else if (provider.providerId === 'google.com') {
                    console.log("Native platform... signing-in with Google");
                    signInResult = (await FirebaseAuthentication.signInWithGoogle());
                } else {
                    console.log("Native platform... signing-in with Apple");
                    signInResult = (await FirebaseAuthentication.signInWithApple());
                }
                console.log("Native platform... Signed in");
                if (signInResult.user) {
                    console.log("Logged in", signInResult)
                    if (provider.providerId === 'facebook.com') {
                        esApi.esAuth = signInResult.credential?.accessToken!
                    } else {
                        esApi.esAuth = signInResult.credential?.idToken!
                    }
                    await esApi.login()
                    navigate('/escal')
                } else {
                    console.log("Login failed", signInResult)
                }
            } else {
                await signInWithPopup(auth.current, provider)
            }
        } catch (err: any) {
            setIsSigningIn(false);
            if (err.code === 'auth/popup-closed-by-user') {
                setErrorMessage(
                    i18n(
                        `Failed to ${variant === 'up' ? 'sign-up' : 'login'} because the {a0} popup window failed to open or was closed by the user.`,
                        {a0: provider.providerId}
                    )
                )
            }
        }
    }

    async function resetPassword() {
        if (testEmail(formState.values.email)) {
            // clear password
            setFormState({
                ...formState,
                values: {
                    ...formState.values,
                    password1: '',
                    password2: ''
                },
                focused: {
                    ...formState.focused,
                    password1: false,
                    password2: false
                }
            })
            setWaitingPasswordReset(true);
            // make sure password1 field gets focus
            document.getElementById('password1')?.focus();
            await sendPasswordResetEmail(auth.current, formState.values.email);
        }
    }

    function openSignUpVariant() {
        setAlreadyUsedEmailError('')
        setVariant('up');
        // add a 'signup=true' to our query string
        navigate('/login?signUp=true', {replace: true})
    }

    function openLoginVariant() {
        setAlreadyUsedEmailError('')
        setVariant('in')
        // add signUp=true QP
        navigate('/login', {replace: true})
    }

    function handleFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        const {id, value} = e.target;
        const state = clearErrors();
        const newState = {
            ...state,
            values: {
                ...formState.values,
                [id]: value,
                // errors: {},
            },
        };
        setErrorMessage('')
        setFormState(newState);
    }


    function setLostFocus(event: React.FocusEvent<HTMLInputElement>) {
        setFormState(
            {
                ...formState,
                focused: {
                    ...formState.focused,
                    [event.target.id]: true
                }
            }
        )
    }

    function hasErrors(formState: any) {
        if (canSubmit == false) {
            return true;
        }
        if (formState.errors.email) {
            return true;
        }
        if (formState.errors.password1) {
            return true;
        }
        if (formState.errors.password2) {
            return true;
        }
    }

    async function signIn() {
        try {
            await signInWithEmailAndPassword(auth.current, formState.values.email, formState.values.password1)
            // this should in theory trigger the onAuthStateChanged event
        } catch (e: any) {
            setErrorMessage(i18n('Invalid email or password'));
        }
    }

    async function signUp() {
        try {
            const signInResult = await createUserWithEmailAndPassword(auth.current, formState.values.email, formState.values.password1)
            if (signInResult.user) {
                if (!userIsVerified(signInResult.user)) {
                    sendAnotherEMail(signInResult.user as User)
                }
            }
        } catch (e: any) {
            console.log("Already used");
            setAlreadyUsedEmailError(formState.values.email);
        }
    }

    function signInOrUp(e: any) {
        e.preventDefault()
        if (checkErrors(true)) {
            return;
        }
        if (variant === 'in') {
            // noinspection JSIgnoredPromiseFromCall
            signIn();
        } else {
            // noinspection JSIgnoredPromiseFromCall
            signUp();
        }
    }

    function sendAnotherEMail(user: FirebaseOrCapacitorFirebaseUser) {
        // noinspection JSIgnoredPromiseFromCall
        sendEmailVerification(user as User);
    }

    function continueFlow(): void {
        setDisplayVerifyEMailDialog(false);
    }



    function backToLogin(): void {
        auth.current.signOut();
    }

    return (

        <div className={styles.main}>
            <h1><SimpleI18NMessage msg={'Login'}/></h1>
            <div className={styles.signInOptions}>
                <SignInWithGoogle onSignIn={() => doSignIn(new GoogleAuthProvider())}/>
                <SignInWithFacebook onSignIn={() => doSignIn(new FacebookAuthProvider())}/>
                <SignInWithApple onSignIn={() => doSignIn(_APPLE_PROVIDER)}/>
                {/*<span style={{fontSize: "xx-small"}}><SimpleI18NMessage*/}
                {/*    msg={'Facebook ID pending organization authorization. Coming soon.'}/></span>*/}
            </div>
            <div className={styles.horizontalLine}/>
            <div className={styles.emailSignIn + ' ' + styles.email}>
                <span><SimpleI18NMessage msg={`Or sign-${variant} with email`}/></span>
                <form onSubmit={signInOrUp}>
                    <TextField label={i18n('Email')} value={formState.values.email} variant="outlined" type="email"
                               placeholder={i18n("email")}
                               error={!!formState.errors.email} helperText={formState.errors.email}
                               onBlur={setLostFocus}
                               id={'email'} fullWidth margin="normal" onChange={handleFieldChange}/>
                    <TextField className={formState.warnings.password1 ? styles.warning : ''}
                               label={i18n('Password')} value={formState.values.password1} variant="outlined" type="password"
                               placeholder={i18n("password")}
                               id={'password1'} fullWidth margin="normal" onChange={handleFieldChange}
                               onBlur={setLostFocus}
                               error={!!formState.errors.password1 || !!formState.warnings.password1} helperText={formState.errors.password1 || formState.warnings.password1}
                    />
                    <PasswordStrengthBar className={styles.passwordStrength + (variant === 'up' ? '' : ' hidden')}
                                         password={formState.values.password1}
                                         onChangeScore={(s) => setPasswordStrength(s)}/>
                    {variant === 'up' && (<>
                        <TextField label={i18n("Confirm Password")} value={formState.values.password2} variant="outlined"
                                   type="password"
                                   placeholder={i18n("confirm password")} id={'password2'} fullWidth margin="normal"
                                   onChange={handleFieldChange}
                                   onBlur={setLostFocus} error={!!formState.errors.password2}
                                   helperText={formState.errors.password2}
                        />
                    </>)}
                    {errorMessage && (<>
                        <div className={styles.errorMessage}>{errorMessage}</div>
                    </>)}
                    <div>&nbsp;</div>
                    <Button type="submit" variant="contained" color="primary" disabled={hasErrors(formState)}
                            onClick={signInOrUp}>
                        <SimpleI18NMessage msg={`Sign-${variant}`}/>
                    </Button>
                </form>
            </div>

            <div className={styles.horizontalLine}/>
            <Link onClick={resetPassword}><SimpleI18NMessage msg={'Forgot password?'}/></Link>
            {variant === 'in' &&
                <Link onClick={openSignUpVariant}><SimpleI18NMessage msg={'Don\'t have an account?'}/></Link>}
            {variant === 'up' &&
                <Link onClick={openLoginVariant}><SimpleI18NMessage msg={'Already have an account?'}/></Link>}


            { /* Dialog for when user is waiting for email verification */
             user && !userIsVerified(user) && (
            <Dialog open={true} onClose={backToLogin}>
                <DialogTitle>Please verify your email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {variant === 'up' && (
                            <p><I18N>An email has been sent to </I18N><b>{user!.email}</b>. <I18N>Please follow the link in this email to verify
                                your user id.</I18N></p>)}
                        {variant === 'in' && (
                            <p><I18N>You are logging in using the email </I18N><b>{user!.email}</b><I18N>. This email
                                has not yet been verified.
                                Please check your email for a verification link. If you cannot find it, please request
                                another link by pressing the "Send another link" link, below.</I18N></p>
                        )}
                    </DialogContentText>
                    <DialogActions>
                        <Link onClick={() => sendAnotherEMail(user!)}><SimpleI18NMessage
                            msg={'Send another link'}/></Link>
                        <Link onClick={backToLogin}><SimpleI18NMessage msg={'Back to login'}/></Link>
                    </DialogActions>
                </DialogContent>
            </Dialog>)}

            { /* Dialog for once email has been verified.  Intentional so user doesn't get lost */
                user && userIsVerified(user) && displayVerifyEMailDialog && (
            <Dialog open={true} onClose={continueFlow}>
                <DialogTitle><I18N>Email verified</I18N></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <I18N>Your email has now been verified. Thank you.</I18N>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={continueFlow} autoFocus><I18N>Continue</I18N></Button>
                </DialogActions>
            </Dialog>)}


            { /* Dialog for when a password reset link has been sent */
                formState.values.email && waitingPasswordReset && (
                    <Dialog open={true} onClose={continueFlow}>
                        <DialogTitle><I18N>Email reset</I18N></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <I18N>A password reset link has been set to</I18N><b> {formState.values.email}</b>. <I18N>Please check your email.</I18N>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setWaitingPasswordReset(false)} autoFocus><I18N>Continue</I18N></Button>
                        </DialogActions>
                    </Dialog>)}
        </div>
    );
};

export default LoginPage;

function testEmail(email: string) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email);
}

