import {EntrySummary, MAX_ENTRIES_PER_DAY, seSmallImage} from "../../shared/types";
import styles from './styles.module.css';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {Icon} from "@mui/material";
import {FormatDateOptions, useIntl} from "react-intl";
import {SimpleI18NMessage} from "../../utils/i18n";
import {TUT_2_CLASSNAME} from "../tutorial";

export interface DayEntryProps {
    date: string;
    loadingEntry: boolean;
    error: string
    entries: EntrySummary[];
}

export const DayEntry = (props: DayEntryProps) => {
    // date is as yyyymmdd, display it as Monday, Nov 3.
    const navigate = useNavigate();
    const intl = useIntl();

    const options : FormatDateOptions = {
        weekday: 'long',
        month: 'short',
        day: 'numeric'
    }
    const dateStr = intl.formatDate(new Date(
        parseInt(props.date.slice(0, 4)),
        parseInt(props.date.slice(4, 6)) - 1,
        parseInt(props.date.slice(6, 8))
    ), options);

    function handleClick(date: string, id: number) {
        navigate(`/dayview/${date}.${id}`)
    }

    return (
        <div className={styles.dayItem}>
            <div>{dateStr}</div>
            {props.loadingEntry && <div><SimpleI18NMessage msg={"Loading..."}/></div>}
            {props.error && <div>Error: {props.error}</div>}
            {!props.loadingEntry && !props.error && (
            <div style={{display: "flex", flexDirection: 'row'}}>
                {props.entries.length === 0 && (
                    <div className={styles.imageHolder + ' ' + styles.empty}>
                            (<SimpleI18NMessage msg='No journal entries for this day.'/>)
                    </div>
                )}
                <div className={styles.scrollable}>
                {props.entries.map((e, id) => {
                    const hasImage = e.textHash && e.textHash.length > 10 && e.imageUrl
                    return (
                        <div key={id} className={styles.imageHolder} onClick={() => {
                            handleClick(props.date, id)
                        }}>
                            {
                                e.imageUrl && (<>
                                    <img src={seSmallImage(e.imageUrl)}/>
                                    <div className={styles.summaryEmoji}>{e.summaryEmoji?.split(',')[1]}</div>
                                </>)
                            }
                            {
                                !e.imageUrl && (<>
                                    <div className={styles.partialJE}>
                                        {e.summaryEmoji ? "Incomplete journal reflection" : "Partial journal entry"}
                                        <br/> <br/>
                                        click to continue...
                                    </div>
                                    <div className={styles.summaryEmoji}>{e.summaryEmoji?.split(',')[1]||'?'}</div>
                                </>)
                            }
                        </div>
                    )
                })}
                </div>
                {props.entries.length < MAX_ENTRIES_PER_DAY && (
                    <div className={styles.imageHolder + ' ' + styles.addEntry} onClick={() => handleClick(props.date, props.entries.length)}>
                        <Button variant="contained" color="primary" data-testid={'add-button'}>
                            <IconButton className={TUT_2_CLASSNAME}>
                                <Icon>+</Icon>
                            </IconButton>
                        </Button>
                    </div>
                )}
            </div>)}
        </div>
    )
}