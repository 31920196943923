import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import styles from './styles.module.css';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import EditIcon from '@mui/icons-material/Edit';
import {DEFAULT_PAPER_SIZE, DPI, Line, Point} from "../../shared/types";
import {useSimpleI18n} from "../../utils/i18n";


const MAX_ZOOM = 10;
const MIN_ZOOM = .25;
export const ANIMATION_TIMEOUT = 500;

interface ScribEntryDraw2Props extends React.HTMLAttributes<HTMLElement> {
    lines: Line[],
    skipRedraws?: boolean,
    onLinesChanged?: (lines: Line[], otherText:string) => void
    onPendingChanges?: () => void
}

interface ScribEntryDraw2State {
    isErasing: boolean
    scale: number
    pan: Point
    lines: Array<Line>
}

interface OneTouchEvent {
    startTime: number
    points: Array<Point>
    uncommittedLines: Array<Line>
    originalLines: Array<Line>
    ctx: CanvasRenderingContext2D|undefined
}

interface TwoTouchEvent {
    start: [Touch, Touch]
    scale: number,
    pan: Point
}

const defaultState = {
    isDrawing: undefined,
    isErasing: false,
    scale: 1.0,
    pan: {x: 0, y: 0},
    lines: []
} as ScribEntryDraw2State;

function calculateDistance(p1: Touch|Point|Line, p2: Touch|Point|undefined) {
    const x0 = (p1 as Touch)?.pageX || (p1 as Point)?.x || (p1 as Line)?.x0 || 0;
    const x1 = (p2 as Touch)?.pageX || (p2 as Point)?.x || (p1 as Line)?.x1 || 0;
    const y0 = (p1 as Touch)?.pageY || (p1 as Point)?.y || (p1 as Line)?.y0 || 0;
    const y1 = (p2 as Touch)?.pageY || (p2 as Point)?.y || (p1 as Line)?.y1 || 0;
    return Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
}

function shortestDistance(p: Point, l: Line) {
    const v: Point = {x: l.x0, y: l.y0};
    const w: Point = {x: l.x1, y: l.y1};
    const l2 = calculateDistance(v, w);
    if (l2 == 0) return calculateDistance(p, v);
    let t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    t = Math.max(0, Math.min(1, t));
    return calculateDistance(p, {x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y)});
}

const ScribEntryDraw2 = forwardRef((props: ScribEntryDraw2Props, ref) => {
    const i18n = useSimpleI18n()
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const lastTimeCanvasSizeUpdated = useRef(Date.now() - 5000);
    const idRef = useRef<HTMLDivElement | null>(null);
    const pendingRedraw = useRef<number | null>(null);
    const doOnChangeTimeout = useRef<NodeJS.Timeout | null>(null);
    const twoTouchEvent = useRef<TwoTouchEvent | null>(null);
    const oneTouchEvent = useRef<OneTouchEvent | null>(null);
    const hasUsedDeviceInLast2Minutes = useRef(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(undefined as boolean | undefined);
    const xyOffset = useRef<Point>({x: 0, y: 0});
    const canvasZoom = useRef<number>(1);

    const [state, setState] = useState(defaultState)
    const stateRef = useRef(state);
    const undos = useRef([] as Array<Line[]>)
    
    useEffect(() => {
        stateRef.current = state;
    }, [state, state.lines, state.pan, state.scale]);

    /**
     * Convert a pageX, pageY coordinate to a canvas coordinate.
     * @param pageX
     * @param pageY
     */
    function convertPoint(pageX: number, pageY: number) {
        return {
            x: ((pageX - xyOffset.current.x) / canvasZoom.current) / stateRef.current.scale - stateRef.current.pan.x,
            y: ((pageY - xyOffset.current.y) / canvasZoom.current) / stateRef.current.scale - stateRef.current.pan.y,
        };
    }

    function createOneTouchEventCtx(_xy: Point) {
        return canvasRef.current!.getContext('2d')!;
    }

    function toggleDrawMode() {
        hasUsedDeviceInLast2Minutes.current = false;
        setState( (prevState) => ({
            ...prevState,
            isErasing: !prevState.isErasing
        }))
        onTouchEnd();
    }

    function undo() {
        if (undos.current.length > 0) {
            const previousLines = undos.current.pop();
            if (previousLines) {
                setState((prevState) => ({...prevState, lines: previousLines || []}));
                if (props.onLinesChanged)
                    props.onLinesChanged(previousLines, "MainID == " + idRef.current!.id);
            } else {
                console.log("Nothing to undo");
            }
        }
    }

    function redrawCanvas() {
        if (pendingRedraw.current) {
            cancelAnimationFrame(pendingRedraw.current);
        }
        if (props.skipRedraws) {
            return;
        }
        if (oneTouchEvent.current?.ctx) {
            // we are drawing, don't redraw yet.  Try again in 25msg
            pendingRedraw.current = requestAnimationFrame(redrawCanvas);
        } else {
            pendingRedraw.current = requestAnimationFrame(_redrawCanvas);
        }
    }

    function _redrawCanvas() {
        pendingRedraw.current = null;
        const canvas = canvasRef.current!;
        const ctx = canvas.getContext('2d')!;
        console.log("Redrawing canvas");
        ctx.resetTransform();
        ctx.clearRect(0, 0, canvas.width * 2, canvas.height * 2);
        ctx.scale(stateRef.current.scale, stateRef.current.scale);
        ctx.translate(stateRef.current.pan.x, stateRef.current.pan.y);

        ctx.strokeStyle = '#000000'
        ctx.beginPath()
        ctx.rect(0, 0, DEFAULT_PAPER_SIZE.width * DPI , DEFAULT_PAPER_SIZE.height * DPI );
        ctx.stroke()


        for (let y = .75 * DPI; y <= DEFAULT_PAPER_SIZE.height * DPI - 0.25 * DPI; y += DPI / 3) {
            // Draw vertical line
            ctx.strokeStyle = '#9ABAD9'
            ctx.beginPath();
            ctx.moveTo(.25/2*DPI, y)
            ctx.lineTo(DEFAULT_PAPER_SIZE.width * DPI - .25 * DPI, y);
            ctx.stroke();
        }

        ctx.strokeStyle = '#FFB6C1'
        ctx.beginPath();
        ctx.moveTo(DPI * .75, 0);
        ctx.lineTo(DPI * .75, DEFAULT_PAPER_SIZE.height * DPI);
        ctx.stroke();

        if (stateRef.current.lines.length > 0) {
            ctx.strokeStyle = '#000000'
            for (const line of stateRef.current.lines) {
                ctx.beginPath();
                ctx.moveTo(line.x0, line.y0);
                ctx.lineTo(line.x1, line.y1);
                ctx.stroke();
            }
        }
    }

    function convertToLines(points: Array<Point>): Line[] {
        const lines = [] as Line[];
        if (points.length === 1) {
            return [{
                x0: points[0].x,
                y0: points[0].y,
                x1: points[0].x+1,
                y1: points[0].y+1
            }]
        }
        for (let i = 0; i < points.length - 1; i++) {
            lines.push({
                x0: points[i].x,
                y0: points[i].y,
                x1: points[i + 1].x,
                y1: points[i + 1].y
            })
        }
        return lines;
    }

    useEffect(() => {
        setState((prevState) => ({...prevState, lines: props.lines}));
    }, [props.lines]);


    function onMouseStart(event: MouseEvent) {
        if (hasUsedDeviceInLast2Minutes.current) return;
        onTouchStart({
            preventDefault: () => event.preventDefault(),
            touches: [{
                pageX: event.pageX,
                pageY: event.pageY
            }]
        } as any);
    }

    function onMouseMove(event: MouseEvent) {
        if (hasUsedDeviceInLast2Minutes.current) return;
        onTouchMove({
            preventDefault: () => event.preventDefault(),
            touches: [{
                pageX: event.pageX,
                pageY: event.pageY
            }]
        } as any)
    }

    function onMouseEnd(_event: MouseEvent) {
        onTouchEnd();
    }

    function updateCanvasSize() {
        requestAnimationFrame(() => {
            const canvas = canvasRef.current!;
            if (!canvas) {
                return;
            }
            const parent = canvas.parentElement!;
            const rect = parent.getBoundingClientRect();
            xyOffset.current = {x: rect.left + window.scrollX, y: rect.top + window.scrollY};
            if ((canvas.width !== parent.clientWidth - 10 || canvas.height !== parent.clientHeight - 10) && parent.clientWidth > 50 && parent.clientHeight > 50) {
                canvas.width = parent.clientWidth - 10;
                canvas.height = parent.clientHeight - 10;
            }
            lastTimeCanvasSizeUpdated.current = Date.now();
            redrawCanvas();
        });
    }

    function onDeviceMove(event: TouchEvent) {
        onTouchMove(event);
    }
    function onDeviceStart(event: TouchEvent) {
        onTouchStart(event);
    }

    useEffect(() => {
        updateCanvasSize();
        const canvasElement = canvasRef.current!;
        canvasElement.addEventListener('touchstart', onDeviceStart, {passive: false});
        canvasElement.addEventListener('touchmove', onDeviceMove, {passive: false});
        canvasElement.addEventListener('touchend', onTouchEnd, {passive: false});
        // add the same events for mouse
        canvasElement.addEventListener('mousedown', onMouseStart, {passive: false});
        canvasElement.addEventListener('mousemove', onMouseMove, {passive: false});
        canvasElement.addEventListener('mouseup', onMouseEnd, {passive: false});
        canvasElement.addEventListener('mouseout', onMouseEnd, {passive: false});


        redrawCanvas();

        // keep track of top x/y relative to screen we will be adjusting mouse events by this.

        twoTouchEvent.current = null;
        oneTouchEvent.current = null;

        const resizeObserver = new ResizeObserver(_entries => {
            updateCanvasSize();
        });

        const parentElement = canvasElement.parentElement;
        if (parentElement) {
            resizeObserver.observe(parentElement);
        }

        // Remember to remove event listeners on cleanup
        return () => {
            canvasElement.removeEventListener('touchstart', onDeviceStart);
            canvasElement.removeEventListener('touchmove', onDeviceMove);
            canvasElement.removeEventListener('touchmove', onTouchEnd);
            canvasElement.removeEventListener('mousedown', onMouseStart);
            canvasElement.removeEventListener('mousemove', onMouseMove);
            canvasElement.removeEventListener('mouseup', onMouseEnd);
            canvasElement.removeEventListener('mouseout', onMouseEnd);
            canvasElement.removeEventListener('pointerleave', onTouchEnd);
            canvasElement.removeEventListener('pointerenter', onTouchEnd);
            if (parentElement) {
                resizeObserver.unobserve(parentElement);
                resizeObserver.disconnect();
            }
        }
    }, []);

    useEffect(() => {
        updateCanvasSize();
    });

    useEffect(() => {
        if (isFullScreen) {
            // noinspection JSIgnoredPromiseFromCall
            idRef.current!.requestFullscreen();
        } else if (isFullScreen !== undefined) {
            document.exitFullscreen().catch(() => {});
        }
    }, [isFullScreen]);


    function onTouchStart(event: TouchEvent) {
        const shouldIgnoreEvent = event.touches.length === 1 && event.touches[0].force && event.touches[0].force >= 1 && hasUsedDeviceInLast2Minutes.current;
        if (shouldIgnoreEvent) {
            return;
        }
        event.preventDefault();
        if (event.touches.length === 1) {
            // we don't commit lines until there is a 1-second pause in drawing (to minimize redrawing)
            // or until there is a two touch event.  So if there are pending changes, we will keep adding
            // to them.
            closePath();
            const xy = convertPoint(event.touches[0].pageX, event.touches[0].pageY);
            oneTouchEvent.current = {
                startTime: oneTouchEvent.current?.startTime || Date.now(),
                ctx: createOneTouchEventCtx(xy),
                points: stateRef.current.isErasing ? [] : [xy],
                originalLines: stateRef.current.lines,
                uncommittedLines: oneTouchEvent.current?.uncommittedLines || []
            }
            setIsMouseDown(true);
        }
        return;
    }


    function onTouchMove(event: TouchEvent) {
        const isPenEvent = event.touches.length === 1 && event.touches[0].force && event.touches[0].force < .95;
        const shouldIgnoreEvent = hasUsedDeviceInLast2Minutes.current && event.touches.length === 1 && !isPenEvent;
        if (shouldIgnoreEvent) {
            return;
        }
        if (!stateRef.current) {
            return;
        }
        if (doOnChangeTimeout.current) {
            queueOnLinesChanged(stateRef.current.lines)
        }
        event.preventDefault();
        if (event.touches.length === 2) {
            // IF Is drawing, cancel events done since drawing start.  This handles scenario where
            // pinching is off skew and the user touches with one finger first before the second finger
            // event registers.
            if (oneTouchEvent.current && oneTouchEvent.current.startTime > Date.now() - 1000 && oneTouchEvent.current?.uncommittedLines.length < 5) {
                // discard these points, noise from the user's fingers not coming down at the same time.
                oneTouchEvent.current = null
            }
            if (oneTouchEvent.current) {
                closePath()
                commitLines()
            }

            if (!twoTouchEvent.current) {
                twoTouchEvent.current = {
                    start: [event.touches[0], event.touches[1]],
                    scale: stateRef.current.scale,
                    pan: stateRef.current.pan
                };
                return;
            }
            const this12Distance = calculateDistance(event.touches[0], event.touches[1]);
            const prev12Distance = calculateDistance(twoTouchEvent.current!.start[0], twoTouchEvent.current!.start[1]);
            const pinchDistance = this12Distance - prev12Distance;
            const tpxDistance = event.touches[0].pageX - twoTouchEvent.current.start[0].pageX;
            const tpyDistance = event.touches[0].pageY - twoTouchEvent.current.start[0].pageY;
            const doPinch = Math.abs(pinchDistance) > Math.abs(tpxDistance) && Math.abs(pinchDistance) > Math.abs(tpyDistance);
            const newScale = Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, twoTouchEvent.current!.scale + (doPinch ? pinchDistance : 0) / 250));
            // if scale = 1, 150 pixels represents 1 inch
            // our overall width if 150 * 8.5 = 1275 pixels
            // if our width is 1275, maxX = 0
            // if our width is 275, maxX = -1000
            // so maximumX = width visible - total width
            // if scale = 2, 300 pixels represents 1 inch
            // our overall width is 300 * 8.5 = 2550 pixels
            // if our width is 2550, maxX = 0
            // if our width is 275, maxX = -2275
            // so maximumX = -(total scaled width - width visible)
            const maxX = -(DEFAULT_PAPER_SIZE.width * DPI * newScale - canvasRef.current!.width) / newScale;
            const maxY = -(DEFAULT_PAPER_SIZE.height * DPI * newScale - canvasRef.current!.height) / newScale;
            const newPan = doPinch ? twoTouchEvent.current!.pan : {
                x: Math.min(0, Math.max(maxX, twoTouchEvent.current!.pan.x + tpxDistance)),
                y: Math.min(0, Math.max(maxY, twoTouchEvent.current!.pan.y + tpyDistance))
            };
            setState((prevState) => ({...prevState, scale: newScale, pan: newPan}));
        } else {
            if (isPenEvent) {
                hasUsedDeviceInLast2Minutes.current = true;
                setTimeout(() => hasUsedDeviceInLast2Minutes.current = false, 120000);
            }
        }
        if (event.touches.length === 1 && oneTouchEvent.current?.ctx && stateRef.current.isErasing) {
            const xy = convertPoint(event.touches[0].pageX, event.touches[0].pageY);
            // we're erasing, see what lines intersect with the current point, and remove them.
            const lines = stateRef.current.lines;
            const newLines = [] as Line[];
            for (const line of lines) {
                const pointDistanceToLine = shortestDistance(xy, line);
                if (pointDistanceToLine > 5) {
                    newLines.push(line);
                }
            }
            setState((prevState) => ({...prevState, lines: newLines}));
            _redrawCanvas();
            if (props.onPendingChanges) {
                props.onPendingChanges();
            }
            return;
        }
        if (event.touches.length === 1 && oneTouchEvent.current?.ctx && !stateRef.current.isErasing) {
            // If we are drawing, add the new point to the line.
            const xy1 = convertPoint(event.touches[0].pageX, event.touches[0].pageY);
            const xy0 = oneTouchEvent.current!.points[oneTouchEvent.current!.points.length - 1]
            if (!xy0) {
                // there is no previous touch.  Start a touch
                onTouchStart(event);
                return;
            }
            // if the line is a straight line that is more than .75 inches long, ignore it.  Do a touch end, and a touch start
            // something weird happened, and since this is a writing app, there should be no straight lines.
            const lineLengthInches =  calculateDistance(xy0, xy1);
            if (lineLengthInches > .75 * DPI) {
                console.log("Long line detected: " + lineLengthInches)
                onTouchEnd();
                setTimeout(() => onTouchStart(event), 1);
                return;
            }

            oneTouchEvent.current!.points.push(xy1);
            const ctx = oneTouchEvent.current!.ctx!;
            ctx.strokeStyle = '#000000'
            ctx.beginPath();
            ctx.moveTo(xy0.x, xy0.y);
            ctx.lineTo(xy1.x, xy1.y);
            ctx.stroke()
            if (props.onPendingChanges) {
                props.onPendingChanges();
            }
        }
    }

    function closePath() {
        if (!oneTouchEvent.current?.ctx) {
            return;
        }
        oneTouchEvent.current?.uncommittedLines.push(...convertToLines(oneTouchEvent.current.points));
        oneTouchEvent.current.ctx = undefined;
    }

    function queueOnLinesChanged(_lines: Array<Line>) {
        if (!props.onLinesChanged && !props.onPendingChanges) {
            return;
        }
        if (doOnChangeTimeout.current) {
            clearTimeout(doOnChangeTimeout.current);
        }
        doOnChangeTimeout.current = setTimeout(() => {
            doOnChangeTimeout.current = null;
            if (props.onLinesChanged) {
                props.onLinesChanged(stateRef.current.lines, "MainID == " + idRef.current!.id);
            }
        }, 1500);
        if (props.onPendingChanges) {
            props.onPendingChanges();
        }
    }

    function commitLines() {
        if (!oneTouchEvent.current) {
            return false;
        }
        undos.current.push(stateRef.current.isErasing ? oneTouchEvent.current?.originalLines : stateRef.current.lines);
        const lines = oneTouchEvent.current.uncommittedLines;
        const newLines = [...stateRef.current.lines, ...lines];
        queueOnLinesChanged(stateRef.current.lines);
        oneTouchEvent.current = null;
        setState((prevState) => ({...prevState, lines: newLines}));
        return true;
    }

    function onTouchEnd() {
        setIsMouseDown(false);
        if (oneTouchEvent.current) {
            closePath();
            commitLines();
        }
        twoTouchEvent.current = null;
    }

    useImperativeHandle(ref, () => ({
        redrawCanvas,
        updateCanvasSize
    }))

    function clear() {
        hasUsedDeviceInLast2Minutes.current = false;
        if (stateRef.current.lines.length > 10) {
            undos.current.push(stateRef.current.lines);
        }
        if (props.onLinesChanged)
            props.onLinesChanged([], "MainID == " + idRef.current!.id);
        setState((prevState) => ({...prevState, lines: []}));
    }

    const eraserCursorSVG = encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10"><circle cx="5" cy="5" r="5" fill="black" /></svg>`);
    const cursorStyle = {
        cursor: stateRef.current.isErasing ? `url('data:image/svg+xml;utf8,${eraserCursorSVG}'), auto` : 'default',
    };

    function fullScreen() {
        setIsFullScreen(!isFullScreen)
    }

    return (
        <div {...props} ref={idRef}>
        <div style={{display: 'flex', flexDirection: 'row', width:'100%', height:'100%'}}>
            <div style={{width: '100%', height: '100%', overflow: 'auto'}}>
            <canvas className={styles.canvas}
                    ref={canvasRef}
                    style={{
                        border: '1px solid black',
                        ...cursorStyle
                    }}
            />
            </div>
            <div className={styles.sidebar + (isMouseDown ? ' ' + styles.sidebarActive : '')}>
                <IconButton title="Full Screen" className={isFullScreen ? styles.selectedIcon : ''} onClick={fullScreen}><FitScreenIcon/></IconButton>
                {/*<IconButton title={i18n("Save")} onClick={save}><SaveIcon/></IconButton>*/}
                <IconButton title={i18n("Clear")} onClick={clear}><DeleteIcon/></IconButton>
                <IconButton title={i18n("Undo")} onClick={undo}><UndoIcon/></IconButton>
                {/*<IconButton title={i18n("Actual Size")} onClick={reset}><span style={{fontSize: 'small'}}>1:1</span></IconButton>*/}
                {/*<IconButton title={i18n("View Page Width")} onClick={viewPageWidth}>*/}
                {/*    <img src='/fullWidth.png' style={{width: '24px', height: '24px'}}/>*/}
                {/*</IconButton>*/}
                {/*<IconButton title={i18n("View Whole Page")} onClick={viewWholePage}>*/}
                {/*    <img src='/fullPage.png' style={{width: '24px', height: '24px'}}/>*/}
                {/*</IconButton>*/}
                <IconButton title={i18n("Edit")} className={!state.isErasing ? styles.selectedIcon : ''} onClick={() => state.isErasing && toggleDrawMode()}><EditIcon/></IconButton>
                <IconButton title={i18n("Erase")} className={state.isErasing ? styles.selectedIcon : ''} onClick={() => !state.isErasing && toggleDrawMode()}>
                    <img src='/eraser.svg' style={{width: '24px', height: '24px'}}/>
                </IconButton>
            </div>
        </div>
        </div>
    );
})

ScribEntryDraw2.displayName = 'ScribEntryDraw2';

export default ScribEntryDraw2;

