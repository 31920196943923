import styles from "./styles.module.css";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SettingsIcon from '@material-ui/icons/Settings';
import { HTMLAttributes } from "react";

export interface ReflectorProps extends HTMLAttributes<HTMLElement> {
    icons: string[],
    isComplete?: boolean
    completeIconImg?: string|Error;
    isError?: boolean;
    isActionable?: boolean;
    hasStarted?: boolean;
    onClick?: () => void;
}

export const Reflector = (props: ReflectorProps) => {
    return (
        <div className={`${styles.iconMain} ${props.isActionable?'':styles.disabled}`} id={props.id}>
            <div className={styles.icon} onClick={() => props.isComplete && props.isActionable && props.onClick?.()}>
                {props.icons.map((icon, index) => <span key={index}>{icon}</span>)}
            </div>
            {props.isActionable && props.isComplete?(
                <div>
                    {props.completeIconImg && typeof(props.completeIconImg) === 'string' && <img src={props.completeIconImg} className={styles.status + ' ' + styles.complete}
                         onClick={() => props.isComplete && props.isActionable && props.onClick?.()}
                    />}
                    {!props.isError &&
                    <CheckCircleIcon className={styles.status + ' '  + styles.green + ' ' + (props.completeIconImg?styles.withImg:'')}
                                     onClick={() => props.isComplete && props.isActionable && props.onClick?.()}
                    />}
                    {props.isError && <ErrorIcon className={styles.status + ' ' + styles.red}
                                                 onClick={() => props.isComplete && props.isActionable && props.onClick?.()}/>
                    }
                </div>
            ):null}
            {props.isActionable && !props.isComplete && props.hasStarted?<SettingsIcon className={styles.status + ' ' + styles.spinning}/>:null}
        </div>
    );
}

export default Reflector;