import { makeStyles } from '@material-ui/core/styles';
import {Box, CircularProgress} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black overlay with opacity
        zIndex: theme.zIndex.tooltip, // Ensure it's above other elements
    },
    spinner: {
        color: 'white', // You can choose any color
    },
}));

const LoadingOverlay = () => {
    const classes = useStyles();

    return (
        <Box className={classes.overlay}>
            <CircularProgress className={classes.spinner} />
        </Box>
    );
};

export default LoadingOverlay;
