// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols

import {deflate, inflate} from "pako";
import sha256 from 'crypto-js/sha256';
import encHex from 'crypto-js/enc-hex';
import encUtf8 from 'crypto-js/enc-utf8';
import {Line} from "../types";

export const IS_TEST_STRIPE = false

// noinspection SpellCheckingInspection
const B62_CHARS = '01234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const B62_CHARS_LN = B62_CHARS.length;

export const LS_EQQ_E2e_KEY = 'eqqE2e';


export function badSleep(ms: number) {
    const start = Date.now()
    while (Date.now() - start < ms) {
    }
}

export function toBase62(num: number) {
    const c1 = B62_CHARS[num % B62_CHARS_LN];
    const c2 = B62_CHARS[Math.floor(num / B62_CHARS_LN)];
    return c2 + c1;
}

export function toStr(b62Num: string): number {
    const c1 = B62_CHARS.indexOf(b62Num[1]);
    const c2 = B62_CHARS.indexOf(b62Num[0]);
    return c1 + c2 * B62_CHARS_LN;
}

export function compressLines(lines: Array<Line>) {
    return compressNumbers(lines.map(line => [line.x0, line.y0, line.x1, line.y1]).flat())
}

export function isClient(): boolean {
    try {
        return !!window
    } catch (e) {
        return false
    }
}

export function compressNumbers(numbers: Array<number>) {
    // convert to array of 16 bit ints
    const sixteenBitInts = Int16Array.from(numbers)
    const eightBitInts = new Uint8Array(sixteenBitInts.buffer)
    let compressed = deflate(eightBitInts)
    const binaryString = compressed.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
    return btoa(binaryString);
}

// noinspection SpellCheckingInspection
export function newId(ln = 20): string {
    const alphaNumeric = '01234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let randomValues = new Uint8Array(ln);

    // if (typeof process !== 'undefined' && process.versions && process.versions.node) {
        // Node.js environment
        // const crypto = require('crypto');
        // randomValues = crypto.randomBytes(ln);
    // } else if (typeof window !== 'undefined' && window.crypto && typeof window.crypto.getRandomValues === 'function') {
        // Browser environment
        // window.crypto.getRandomValues(randomValues);
    // } else {
        // Fallback for environments without crypto support
        for (let i = 0; i < ln; i++) {
            randomValues[i] = Math.floor(Math.random() * 256);
        }
    // }

    // Convert random bytes to alphanumeric characters
    return Array.from(randomValues, (byte: number) => alphaNumeric[byte % alphaNumeric.length]).join('');
}

export function decompressLines(compressedLines: string): Array<Line> {
    if (!compressedLines) {
        return []
    }
    try {
        const binaryString = atob(compressedLines);

        // Convert binary string to a Uint8Array
        const charCodes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            charCodes[i] = binaryString.charCodeAt(i);
        }
        const eightBitInts = inflate(charCodes)
        if (!eightBitInts) {
            return []
        }
        const sixteenBitInts = new Int16Array(eightBitInts.buffer)
        const lines = []
        for (let i = 0; i < sixteenBitInts.length; i += 4) {
            lines.push({
                x0: sixteenBitInts[i],
                y0: sixteenBitInts[i + 1],
                x1: sixteenBitInts[i + 2],
                y1: sixteenBitInts[i + 3],
            })
        }
        return lines
    } catch (e) {
        console.error("Failed to decompress lines: " + e)
        return [];
    }
}


export function hexToBase62(hexString: string) {
    // Convert hex string to a BigInt
    const num = BigInt('0x' + hexString);
    let encoded = '';
    let n = num;

    // Convert BigInt to base62
    while (n > 0) {
        const remainder = Number(n % BigInt(B62_CHARS_LN));
        encoded = B62_CHARS[remainder] + encoded;
        n = n / BigInt(B62_CHARS_LN)
    }

    return encoded;
}

export function randomString(ln: number) {
    return Array.from({length: ln}, () => B62_CHARS[Math.floor(Math.random() * B62_CHARS_LN)]).join('')
}

export function checksum(str: string)  {
    const hash = sha256(encUtf8.parse(str));
    // Convert the hash to a hex string
    const hexHash = hash.toString(encHex);
    // Convert the hexadecimal hash to Base62
    return hexToBase62(hexHash);
}

export function shortChecksum(str: string)  {
    const hash = sha256(encUtf8.parse(str));
    // Convert the hash to a hex string
    const hexHash = hash.toString(encHex).substring(0, 12);
    // Convert the hexadecimal hash to Base62
    return hexToBase62(hexHash);
}



export function numberOfMinutesSinceDec12023(dte: Date = new Date()) {
    return Math.floor((dte.getTime() - 1672531200000) / 1000 / 60);
}

// noinspection JSUnusedGlobalSymbols
export function minutesSinceDec12023ToDateTime(minutes: number) {
    return new Date(minutes * 60 * 1000 + 1672531200000);
}


export function isEquivalent(a: any, b: any): boolean {
    if (!a) return !b;
    if (!b) return !a;
    return JSON.stringify(a, Object.keys(a).sort()) === JSON.stringify(b, Object.keys(b).sort());
}
