import styles from "./styles.module.css";
import {TUT_6_CLASSNAME} from "../tutorial";
import Dynamicfiller, {WidthHeight} from "../dynamicfiller";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShareIcon from "@mui/icons-material/Share";
import {ShareDialog} from "./sharedialog";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import React, {useContext, useState} from "react";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";

export interface ReflectContentProps {
    onRefresh?: () => void
    onProofs?: () => void
    onThumbsUp?: () => void
    onThumbsDown?: () => void
    onClick?: () => void
    scribEntryId: string;
    displayImage?: string|Error;
    displayText?: string|Error;
}


export const ReflectContent =  (
    {displayText, displayImage, ...props}: ReflectContentProps) => {
    const [isShowShareDialog, setIsShowShareDialog] = useState(false);

    const esApi = useContext(EsApiContext);
    function calculateHeight(parentHeight: number, childrenRects: WidthHeight[]): number {
        return Math.max(0, parentHeight - childrenRects[2].height - childrenRects[1].height) - 4;
    }

    function share() {
        setIsShowShareDialog(true)
    }

    function getText(displayText: string | Error) {
        if (typeof (displayText) === 'string') {
            return displayText;
        } else {
            return (<div>
                    <div className={styles.errorMessage}>{displayText.message}</div>
                    <div>Report this to <a href="mailto:marc@equiquill.com">marc@equiquill.com</a></div>
                    <div>Reference: <span>{esApi.uniquishId}</span></div>
                </div>
                )
                }
                }


                return (
                <div>
            {displayImage && displayText && (
                <>
                    {typeof(displayImage) === 'string' &&
                    <img className={styles.dialogImg + ' ' + TUT_6_CLASSNAME} src={displayImage} alt=""
                         onClick={props.onClick}/>
                    }
                    {typeof(displayImage) !== 'string' &&
                        <div className={styles.dialogImg}>
                            {getText(displayImage)}
                        </div>
                    }
                    <div className={styles.dialogTxt} onClick={props.onClick}>{getText(displayText)}</div>
                </>
            )}
            {!displayImage && displayText && (
                <>
                    <div className={styles.dialogTxt} onClick={props.onClick}>{getText(displayText)}</div>
                </>
            )}
            {displayImage && displayText && <Dynamicfiller heightCalculator={calculateHeight}/>}
            <div className={styles.inappropriate}>
                {props.onRefresh && <RefreshIcon onClick={props.onRefresh}/>}
                {props.scribEntryId && typeof(displayImage) === 'string' && typeof(displayText) === 'string' && <>
                    <ShareIcon onClick={share}/>
                    <ShareDialog
                        open={isShowShareDialog}
                        onClose={() => setIsShowShareDialog(false)}
                        scribEntryId={props.scribEntryId}
                        imageUrl={displayImage}
                        reflection={displayText}
                    />
                </>}
                {displayImage && <span/>}
                {displayImage && <span/>}
                {props.onProofs && displayImage && <BurstModeIcon onClick={props.onProofs}/>}
                {!displayImage && <span/>}
                {props.scribEntryId && <ThumbUpOffAltIcon onClick={props.onThumbsUp}/>}
                {props.scribEntryId && <ThumbDownOffAltIcon onClick={props.onThumbsDown}/>}
            </div>
        </div>


    )
}