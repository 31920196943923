import {
    AlternateImage,
    Character,
    checkIsValidId,
    DaySummary,
    ESUser,
    IDType,
    Reflection,
    ScribEntry,
    ScribSession,
    SETTINGS_PROPERTIES, SharedEntry,
    SubscriptionCheckoutSession,
    Textable,
    UNIQUISH_CLIENT_ID_LENGTH,
    UserSettings,
    VersionInfo
} from "../types";
import axios, {AxiosInstance} from "axios";
import {LS_EQQ_E2e_KEY, newId, numberOfMinutesSinceDec12023} from "./index";
import {getAuth, signOut} from "firebase/auth";
import {cleanProperties, client_app, ES_AUTH_EXPIRY_MINUTES} from "./firebase-client";
import {decryptString, EncryptionParameters, encryptString} from "./crypto";
import {AES, enc} from "crypto-js";
import isMobile, {isServer} from "./misc";


const POSTED_CACHE = new Set<string>();

export enum Refreshable {
    NONE,
    PSYCHOLOGIST_TEXT,
    IMAGE_PROMPT,
    IMAGE_URL,
    EMOJI
}

function setCookie(token: string | undefined) {
    // if on browser, set the cookie
    if (typeof document === 'undefined') {
        return
    }
    const expiry = new Date(Date.now() + 60 * 60 * 1000);
    if (token) {
        document.cookie = `esAuth=${token}; expires=${expiry.toUTCString()}; path=/`
    } else {
        // remove the cookie
        document.cookie = 'esAuth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
    }
}

const SINGLETON = [undefined as EquiScribInternalApi | undefined]



export class EquiScribInternalApi {
    private _axiosInstance: AxiosInstance;
    private _loginPromise: Promise<ScribSession> | undefined;
    private _esAuth: string | undefined = '';
    private _session: ScribSession | undefined = undefined
    private readonly _uniquishId: string; // doesn't have to be unique.  Something short to make tracing easier on server.
    private _versionInfo: VersionInfo | undefined = undefined
    private _langChangedCallback?: (lang: string) => void

    private _settingsPromise: undefined | (Promise<UserSettings>);
    private _e2eKey: Promise<EncryptionParameters>|undefined
    private _logoutPromise: NodeJS.Timeout | undefined;

    get uniquishId(): string {
        return this._uniquishId;
    }

    constructor(params: {
        defaultLanguage?: string,
        defaultServerUrl?: string,
        e2eKey?: EncryptionParameters
    } = {}) {
        SINGLETON[0] = this;
        this._axiosInstance = axios.create();
        this._uniquishId = newId(UNIQUISH_CLIENT_ID_LENGTH);
        this._axiosInstance.defaults.headers['x-es-uniquish-id'] = this._uniquishId;
        this._axiosInstance.defaults.headers['x-es-language'] = params.defaultLanguage || navigator.language.substring(0, 2);

        if (params.e2eKey) {
            this._e2eKey = Promise.resolve(params.e2eKey)
        }

        // set root URL if on capacitor, or if on server
        if (!params.defaultServerUrl && (isMobile() || isServer())) {
            console.log(`Running on ${isServer()?"server":"capacitor"}, setting base URL to https://app.equiquill.com`)
            this._axiosInstance.defaults.baseURL = 'https://app.equiquill.com'
            // this._axiosInstance.defaults.baseURL = 'http://192.168.2.60:3000'
        }
        if (params.defaultServerUrl) {
            this._axiosInstance.defaults.baseURL = params.defaultServerUrl;
        }
        this._loginPromise = undefined;
        // make sure I can get the version number and build date.

        // If a response has an "x-es-language-detected" header, then the server has detected a language change.
        // and we'll need to invoke the callback.
        this._axiosInstance.interceptors.response.use(
            response => {
                const lang = response.headers['x-es-language-detected'];
                if (lang) {
                    this._axiosInstance.defaults.headers['x-es-language'] = lang;
                    if (this._langChangedCallback) {
                        this._langChangedCallback(lang);
                    }
                }
                setCookie(this.esAuth)
                // log us out automatically after 58 minutes
                if (this._logoutPromise) {
                    // cancel previous logout
                    clearTimeout(this._logoutPromise)
                    this._logoutPromise = undefined
                }
                this._logoutPromise = isServer() ? undefined : setTimeout(async () => {
                    window.location.href = '/#/logout'
                }, (ES_AUTH_EXPIRY_MINUTES - 2) * 60 * 1000);
                return response;
            },
            error => {
                return Promise.reject(error);
            }
        );

        this.loadAuthCookie()
        console.log("Constructed EquiScribInternalApi with default URL to " + this._axiosInstance.defaults.baseURL);
    }

    private async heartbeat() {
        try {
            const res = await this._axiosInstance.get('/api/version.json', {timeout: 2000});
            this._versionInfo = res.data as VersionInfo;
            return true;
        } catch (_e) {
            return false;
        }
    }


    loadAuthCookie() {
        // if we're on the server, then we can't load the cookie, so just ignore
        if (typeof document === 'undefined') {
            return
        }
        const cookie = document.cookie.split('; ').find(row => row.startsWith('esAuth='));
        this.esAuth = cookie?.lastIndexOf('=') ? cookie.substring(cookie.lastIndexOf('=') + 1).trim() : undefined;
        console.info(`Created new EquiScribInternalApi session.  Cookie == ${cookie}.`)
    }

    set session(session: ScribSession) {
        this._session = session
    }

    set esAuth(token: string | undefined) {
        this._esAuth = token;
        this._session = undefined
        if (token) {
            this._axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`
        } else {
            delete this._axiosInstance.defaults.headers['Authorization'];
        }
        this._loginPromise = undefined;
    }

    get esAuth(): string | undefined {
        return this._esAuth
    }

    async login() {
        if (!this._esAuth) {
            this._session = undefined
            return this._loginPromise = new Promise<ScribSession>((_resolve, _reject) => {
                throw new Error("Cannot login. No esAuth token")
            })
        }
        if (this._loginPromise) {
            console.trace("Already have a login in progress, or completed");
            return this._loginPromise
        }
        console.info("Logging in using esAuth token " + this._esAuth);
        this._session = undefined
        this._loginPromise = this._axiosInstance.post('/api/login')
            .then(async (res) => {
                const session = res.data
                this._session = res.data as ScribSession
                console.log("Logged IN... setting session id cookie to " + session.id)
                this._esAuth = session.id;
                this._axiosInstance.defaults.headers['Authorization'] = `Bearer ${session.id}`
                setCookie(session.id)
                return session;
            })
            .catch(err => {
                console.log("Error logging in: ", err);
                this.esAuth = undefined;
                // clear cookie too if set
                setCookie(undefined)
                throw err
            })
        return await this._loginPromise;
    }

    get user(): ESUser {
        if (this._session) {
            return this._session.user!;
        } else {
            throw new Error("Not logged in");
        }
    }


    getCancelToken() {
        const ac = new AbortController();
        (ac as any).mrbId = newId();
        return ac;
    }

    async decryptReflection<T extends Reflection|Partial<Reflection>>(reflection: T): Promise<T> {
        const key = await this.getE2eKey();
        return {
            ...reflection,
            text: reflection.text && decryptString(reflection.text, key),
            imageAltText: reflection.imageAltText && decryptString(reflection.imageAltText, key)
        }
    }

    async decryptScribEntry(se: ScribEntry): Promise<ScribEntry> {
        // mutates se, decrypting it.
        const key = await this.getE2eKey();
        se.ocrText = decryptString(se.ocrText, key);
        se.overriddenText = decryptString(se.overriddenText, key);
        se.lines = decryptString(se.lines, key);
        return se;
    }

    async encryptScribEntry(se: ScribEntry, key: EncryptionParameters): Promise<ScribEntry> {
        // mutates se, decrypting it.
        if (!key) {
            key = await this.getE2eKey();
        }
        return {
            ...se,
            ocrText: encryptString(se.ocrText, key),
            overriddenText: encryptString(se.overriddenText, key),
            lines: encryptString(se.lines, key)
        };
    }

    async getScribEntries(id: string): Promise<ScribEntry[]> {
        if (id !== 'first') {
            checkIsValidId(id, IDType.ROOT_OR_CHILD);
        }
        await this._loginPromise;
        const res = await this._axiosInstance.get(`/api/scribentry/${id}`)
        for (const resEntry of res.data) {
            await this.decryptScribEntry(resEntry);
        }
        return res.data as ScribEntry[];
    }

    async getReflection(id: string, cancelToken: AbortController | undefined = undefined) {
        await this._loginPromise;
        const resp = await this._axiosInstance.get(`/api/reflections/${id}`, {
            signal: cancelToken?.signal,
        })
        return this.decryptReflection(resp.data as Reflection);
    }

    async updateReflection(reflection: Reflection, key: EncryptionParameters) {
        await this._loginPromise;
        if (!key) {
            key = await this.getE2eKey();
        }
        const toSave = {
            ...reflection,
            text: encryptString(reflection.text, key),
            imageAltText: encryptString(reflection.imageAltText, key),
        }
        return this._axiosInstance.put(`/api/reflections/${reflection.id}`, toSave);
    }

    async updateReflectionField(scribEntryId: string, key: string, field: keyof Reflection, value: string, AbortController: AbortController | undefined = undefined) {
        await this._loginPromise;
        // encrypt key reflection properties
        if (value) {
            if (field === 'text') {
                value = await this.encryptString(value);
            } else if (field === 'imageAltText') {
                value = await this.encryptString(value);
            }
        }

        const body: Partial<Reflection> = {
            scribEntryId: scribEntryId
        }
        // @ts-ignore
        body[field] = value;
        await this._axiosInstance.patch(`/api/reflections/${key}`, body, {
            signal: AbortController?.signal
        });
    }

    async getMonthSummaries(yearMonth: string): Promise<Array<DaySummary>> {
        await this._loginPromise;
        return this._axiosInstance.get(`/api/daysummaries/${yearMonth}`)
            .then(res => res.data as Array<DaySummary>);
    }

    async getSummaries(startDate: string, endDate: string, cancelToken: AbortController | undefined = undefined) {
        await this._loginPromise;
        return this._axiosInstance.get(`/api/daysummaries/${startDate}-${endDate}`,
            {signal: cancelToken?.signal}
        )
            .then(res => res.data as Array<DaySummary>)
    }

    async getScribEntryOcr(compressedLines: string, cancelToken: AbortController | undefined = undefined): Promise<ScribEntry> {
        await this._loginPromise;
        const se = {
            lines: compressedLines,
            language: this._axiosInstance.defaults.headers['x-es-language']
        }
        return await (this._axiosInstance.post(
            `/api/airequests/ocr`,
            se,
            {signal: cancelToken?.signal}
        ).then(res => res.data))
    }

    async doReflect(text: Textable, cancelToken: AbortController | undefined = undefined) {
        await this._loginPromise;
        const webResponse =  await (this._axiosInstance.post(
            `/api/airequests/text`,
            this.createTextLang(text),
            {signal: cancelToken?.signal}
        ));
        return webResponse.data as Partial<Reflection>;
    }

    async doImagePrompt(text: Textable, force: boolean, cancelToken: AbortController | undefined = undefined) {
        await this._loginPromise;
        const qp = force ? '?force=true' : '';

        const webResponse = await (this._axiosInstance.post(
            `/api/airequests/imageAltText${qp}`,
            this.createTextLang(text),
            {signal: cancelToken?.signal}
        ));
        return webResponse.data as Partial<Reflection>;
    }

    async doImage(text: Textable|undefined, imagePrompt: string, currentImageUrl: string, cancelToken: AbortController | undefined = undefined, model='dall-e-3') {
        await this._loginPromise;
        let qp = currentImageUrl ? `current=${encodeURIComponent(currentImageUrl)}` : '';
        if (model && model !== 'dall-e-3') {
            qp = qp + (qp ? '&' : '') + `model=${model}`
        }
        if (qp) {
            qp = '?' + qp;
        }
        const webResponse = await (this._axiosInstance.post(
            `/api/airequests/image${qp}`,
            {
                ...this.createTextLang(text),
                imageAltText: imagePrompt
            } as Partial<Reflection>,
            {signal: cancelToken?.signal}
        ));
        const response = await this.decryptReflection(webResponse.data as Reflection);
        return {
            ...response,
            ...this.createTextLang(text)
        }
    }

    async updateSettings(settings: UserSettings) {
        await this._loginPromise;
        // remove passwords for settings
        const newSettings = {
            ...cleanProperties(settings, SETTINGS_PROPERTIES)
        } as UserSettings
        return this._axiosInstance.post(`/api/settings`, newSettings)
            .then(res => res.data as UserSettings);
    }

    async getSettings(checkSubscription: boolean = false): Promise<UserSettings> {
        // avoid calling the server if we already have a request in progress
        if (this._settingsPromise) {
            return this._settingsPromise;
        }
        const promise = async () => {
            // this is cheap, but there is a chicken and egg here with login & getting settings, as login gets
            // the settings after login.  So if getting settings waits for login, then it will never end.
            // however, many components may require settings as part of their startup.  Oh, ok sure, we could change
            // those components to make sure logged in first, before getting settings, but that's too easy to
            // miss/get wrong when building components.  So I'd rather just have this block for login / settings.
            // Using a timeout when waiting for login, because if we don't, then we can end up in a deadlock
            // It's a bit of a hack, but it works, and really not that bad.
            let lastWarningTime = Date.now();
            while (true) {
                if (!this._loginPromise) {
                    await new Promise(resolve => setTimeout(resolve, 250));
                    continue;
                }
                try {
                    await this._loginPromise
                    break;
                } catch (e) {
                    // warn every two seconds
                    if (Date.now() - lastWarningTime > 2000) {
                        console.log(`Can't retrieve settings yet ${e}, login promise failed to resolve.  Trying again.`)
                        lastWarningTime = Date.now();
                    }
                    await new Promise(resolve => setTimeout(resolve, 500));
                }
            }
            console.log("Retrieving settings");
            // not sure why this was here, but it was causing the settings to be re-fetched every time.
            // if you need to put back, include comments on why
            // this._settingsPromise = undefined;
            return await (this._axiosInstance.get(`/api/settings?checkSubscription=${checkSubscription}`)
                .then(res => res.data as UserSettings)
                .then(settings => !settings.characters ? {...settings, characters: [] as Character[]} : settings));
        };
        this._settingsPromise = promise();
        return this._settingsPromise;
    }

    async logout() {
        console.log("Logging out");
        this._loginPromise = undefined;
        this.esAuth = '';
        // noinspection JSIgnoredPromiseFromCall
        try {
            await signOut(getAuth(client_app))
        } catch (e) {
            console.warn("Logout failed: ", e)
        }
    }

    get isLoggedIn() {
        return !!(this._session && this._session.uid && this._session.user);
    }


    async updateScribEntryFieldOld(entryId: string, field: keyof ScribEntry, value: string, cancelToken: AbortController | undefined = undefined) {
        checkIsValidId(entryId, IDType.CHILD)
        await this._loginPromise;
        let finalValue = value;
        switch (field) {
            case 'lines':
            case 'overriddenText':
            case 'ocrText':
                finalValue = await this.encryptString(value);
                break;
            case 'emojiUtf8':
            case 'reflectionId':
                // allow these to be saved
                break;
            default:
                // make sure we figure out if to encrypt or not.
                throw new Error("Cannot update field " + field);
        }
        return await this._axiosInstance.put(`/api/scribentry/${entryId}/${field}`, finalValue, {
            headers: {'Content-Type': 'text/plain'},
            signal: cancelToken?.signal
        });
    }

    createTextLang(text: Textable|undefined) {
        return text ? {
            ocrText: text.ocrText,
            overriddenText: text.overriddenText,
            language: this._axiosInstance.defaults.headers['x-es-language']
        } : {}
    }

    async getScribEntryEmoji(id: string, text: Textable, cancelToken: AbortController|undefined) {
        await this._loginPromise;
        const r = await (this._axiosInstance.post(`/api/airequests/emojiUtf8`, this.createTextLang(text), {signal: cancelToken?.signal}));
        const data = r.data as Partial<ScribEntry>;
        await this.updateScribEntryFieldOld(id, 'emojiUtf8', data.emojiUtf8!, cancelToken);
        return data;
    }

    async createReflectionAlternative(imagePrompt: string, model: string, lastUrl: string, cancelToken: AbortController) {
        await this._loginPromise;
        const result = await this.doImage(undefined, imagePrompt, lastUrl, cancelToken, model);
        return {
            url: result.image,
            created: numberOfMinutesSinceDec12023(),
            cost: result.imageCost,
            isCensoredAlternative: false,
            source: model
        } as AlternateImage
    }

    async setPrimaryImage(reflectKey: string, imagePrompt: string, url: string) {
        await this._loginPromise;
        await this._axiosInstance.patch(`/api/reflections/${reflectKey}`, {
            image: url,
            imageAltText: encryptString(imagePrompt, await this.getE2eKey()),
        })
    }

    async getVersion() {
        await this.heartbeat();
        return this._versionInfo;
    }

    onLanguageChanged(setLocale: (value: string) => void) {
        this._langChangedCallback = setLocale;
    }

    async fetchMessages(locale: string): Promise<{ [key: string]: string }> {
        let res = await this._axiosInstance.get('/api/messages', {
            headers: {
                'Accept-Language': locale
            }
        });
        return res.data;
    }

    // TODO P3: Remove / Improve automated translation process

    postMissingKey(msgKey: string) {
        // send the key as the entire body (as plain text)
        if (POSTED_CACHE.has(msgKey)) {
            return;
        }
        POSTED_CACHE.add(msgKey);
        // noinspection JSIgnoredPromiseFromCall
        this._axiosInstance.post('/api/messages', msgKey, {
            headers: {'Content-Type': 'text/plain'}
        });
    }

    fetchStatic(urlToFetch: string) {
        return this._axiosInstance.get(urlToFetch);
    }

    async updateScribEntryNew(value: ScribEntry, key: EncryptionParameters) {
        await this._loginPromise;
        const toSave = await this.encryptScribEntry(value, key)
        return this._axiosInstance.put(`/api/scribentry/${value.id}`, toSave);
    }


    async createLifetimeSubscriptionSession() {
        await this._loginPromise;
        return this._axiosInstance.post(`/api/settings/subscription-co-session`)
            .then(res => res.data as SubscriptionCheckoutSession);

    }

    private async encryptString(value: string) {
        const key = await this.getE2eKey();
        return encryptString(value, key)
    }

    async getE2eKey(): Promise<EncryptionParameters> {
        // if there is a promise, and it is NOT in error, then return it
        if (this._e2eKey) {
            return this._e2eKey;
        }
        if (typeof document === 'undefined') {
            // on server, this cannot be!!
            throw new Error("Cannot get e2e key on server")
        }
        this._e2eKey = (async () => {
            const settings = await this.getSettings(false);
            if (settings.trueE2eKeyVersion) {
                const key = await this.getClientE2eKey(settings.trueE2eKeyVersion);
                return {
                    pseudoE2eKey: settings.pseudoE2eKey!,
                    pseudoE2eKeyVersion: settings.pseudoE2eKeyVersion!,
                    trueE2eKey: key,
                    trueE2eKeyVersion: settings.trueE2eKeyVersion
                } as EncryptionParameters
            } else {
                return {
                    pseudoE2eKey: settings.pseudoE2eKey!,
                    pseudoE2eKeyVersion: settings.pseudoE2eKeyVersion!,
                } as EncryptionParameters
            }
        })();
        return this._e2eKey;
    }

    async updateClientE2eKey(password: string, version: number) {
        if (!version) {
            throw new Error(`Cannot set version to ${version}`)
        }
        const pseudoE2e = (await this.getE2eKey()).pseudoE2eKey
        const encryptedPassword = AES.encrypt(password, pseudoE2e).toString();
        // fetch the key from secure local storage
        localStorage.setItem(LS_EQQ_E2e_KEY+version, encryptedPassword)
    }

    async getClientE2eKey(version: number|undefined) {
        if (!version) {
            return undefined
        }
        // this creates a deadlock.  Go to settings directly
        const settings = await this.getSettings(false);
        const pseudoE2e = settings.pseudoE2eKey!;
        // fetch the key from secure local storage
        const encryptedPassword = localStorage.getItem(LS_EQQ_E2e_KEY+version)!
        if (!encryptedPassword) {
            return undefined
        }
        return AES.decrypt(encryptedPassword, pseudoE2e).toString(enc.Utf8)
    }

    async getScribAndReflectionKeys(e2eKeyType: string): Promise<string[]> {
        await this._loginPromise;
        const items = await this._axiosInstance.get('/api/settings/conversionitems?search=e2emissmatch&e2ekeytype=' + e2eKeyType + '&fields=id')
        return items.data
    }

    async getSampleTextValueForKey(keyType: string): Promise<string> {
        try {
            const result = await this._axiosInstance.get('/api/sampleValue?e2eKeyType=' + keyType);
            return result.data as string;
        } catch (e) {
            console.error("Failed to find sample value for key type " + keyType);
            return '';
        }
    }

    async isMissingPassword() {
        const settings = await this.getSettings(false);
        if (settings.trueE2eKeyVersion) {
            return !localStorage.getItem(LS_EQQ_E2e_KEY+settings.trueE2eKeyVersion);
        } else {
            return false;
        }
    }

    async flagInappropriateContent(param: ScribEntry & Reflection, refreshable: Refreshable | undefined, inappropriateComment: string) {
        await this._loginPromise;
        const body = {
            ...param,
            refreshable: Refreshable[refreshable||0],
            inappropriateComment: inappropriateComment
        }
        await this._axiosInstance.post('/api/concerns', body);
    }

    async deleteAccount(oneThing: string) {
        await this._loginPromise
        const uid = this.user.uid
        // include the reason in the body as plain text
        await this._axiosInstance.delete(
            `/api/users/${uid}`,
            {data: oneThing}
        )
    }

    async shareEntry(entryId: string, scribEntryId: string, encryptedImageUrl: string, encryptedReflection: string) {
        await this._loginPromise;
        const body: SharedEntry = {
            id: entryId,
            uid: this.user.uid,
            scribEntryId:  scribEntryId,
            created: numberOfMinutesSinceDec12023(),
            lastUpdated: numberOfMinutesSinceDec12023(),
            encryptedImageUrl: encryptedImageUrl,
            encryptedReflection: encryptedReflection
        }
        await this._axiosInstance.post(`/api/sharedentries/${entryId}`, body)
    }

    async getSharedEntry(id: string) {
        const se = await this._axiosInstance.get(`/api/sharedentries/${id}`)
        return se.data as SharedEntry
    }

    async deauthorizeStrava() {
        await this._loginPromise;
        await this._axiosInstance.delete('/api/settings/stravaInfo')
    }
}

export const singleton = () => SINGLETON[0]!
