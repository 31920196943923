import {useEffect, useRef, useState} from "react";

export type DynamicFillerProps = {
    heightCalculator: (parentHeight: number, childrenRects: WidthHeight[]) => number
}

export const DynamicFiller = (props: DynamicFillerProps) => {

    const componentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState('0px');

    // when parent resizes, automatically resize this component
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            if (!componentRef.current?.parentElement) {
                return;
            }
            const parent = componentRef.current.parentElement!;
            const parentHeight = parent.offsetHeight;
            const clientRects = []
            for (let i = 0; i < parent.children.length; i++) {
                const child = parent.children[i]
                if (child === componentRef.current) {
                    continue;
                }
                clientRects.push(getFullSizeIncludingMargins(child));
            }
            try {
                const height = props.heightCalculator(parentHeight, clientRects);
                setHeight('' + height +  'px');
            } catch (e) {
                return height;
            }
        });

        if (componentRef.current) {
            resizeObserver.observe(componentRef.current.parentElement!);
        }
        return () => resizeObserver.disconnect();
    }, [props]);




    return (
        <div ref={componentRef} style={{ height: height, backgroundColor: 'red' }}/>
    )
}

export type WidthHeight = {
    width: number,
    height: number
}

function getFullSizeIncludingMargins(element: any) : WidthHeight {
    const rect = element.getBoundingClientRect();
    const style = window.getComputedStyle(element);
    const marginTop = parseInt(style.marginTop, 10);
    const marginBottom = parseInt(style.marginBottom, 10);
    const marginLeft = parseInt(style.marginLeft, 10);
    const marginRight = parseInt(style.marginRight, 10);

    const fullWidthWithMargins = rect.width + marginLeft + marginRight;
    const fullHeightWithMargins = rect.height + marginTop + marginBottom;

    return {
        width: fullWidthWithMargins,
        height: fullHeightWithMargins
    };
}


export default DynamicFiller;