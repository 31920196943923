import {initializeApp} from "@firebase/app";
import {GoogleAuthProvider} from "@firebase/auth";



export const GOOGLE_OAUTH_PATH = '/oauth/google'
export const FB_OAUTH_PATH = '/oauth/fb'

// the string below is automatically changed to prod value on prod deployment
// export const MAIN_DOMAIN = 'https://app.equiquill.com'
// export const MAIN_DOMAIN = 'http://192.168.2.60:3000'
export const MAIN_DOMAIN = 'https://www.equiscrib.com';

// noinspection JSStringConcatenationToES6Template
// eslint-disable-next-line no-useless-concat

let _IS_DEV = false;
if (typeof process !== 'undefined' && process.env && process.env.PROJECT_ID === 'equi-scrib') {
    // Node.js environment
    _IS_DEV = false;
} else {
    // Browser environment
    _IS_DEV = MAIN_DOMAIN === ('http://' + 'localhost:3000')
}

export const IS_DEV = _IS_DEV;

export const firebaseConfigProd = {
    apiKey: "AIzaSyD1Ggbk40lVNLVOZB1m7FUEP6WzBzb82jM",
    authDomain: "equi-scrib.firebaseapp.com",
    projectId: "equi-scrib",
    storageBucket: "equi-scrib.appspot.com",
    messagingSenderId: "1079947989133",
    appId: "1:1079947989133:web:406a07e02ed3b475d892c4",
    measurementId: "G-K71B10S9QL"
}
export const firebaseConfigDev = {
    apiKey: "AIzaSyBz97dOnWKLxmPrFB_bWNmW3umDUCsenik",
    authDomain: "equi-scrib-dev.firebaseapp.com",
    projectId: "equi-scrib-dev",
    storageBucket: "equi-scrib-dev.appspot.com",
    messagingSenderId: "900049816483",
    appId: "1:900049816483:web:a28c8564633ff9cf98a816",
    measurementId: "G-P4WLSQTQJV"
};

export const firebaseConfig = IS_DEV ? firebaseConfigDev : firebaseConfigProd



export const ES_AUTH_EXPIRY_MINUTES = 60


export function verifiedEmail(user: any): string|undefined {
    if (!user) {
        return undefined;
    }
    if (!user.providerData || user.providerData.length !== 1) {
        return undefined;
    }
    if (!user.email && !user.providerData[0].email) {
        return undefined;
    }
    if (['facebook.com', 'google.com', 'apple.com'].indexOf(user.providerData[0].providerId) >= 0) {
        // so google/firebase hasn't verified itself (https://stackoverflow.com/questions/38398545/firebase-facebook-auth-email-verified-always-false)
        // BUT... facebook has verified the email address, so we will trust it.
        return user.email || user.providerData[0].email;
    }
    if (!user.emailVerified) {
        return undefined;
    }
    return user.email
}

export function userIsVerified(user: any): boolean|undefined {
    if (!user) {
        return undefined;
    }
    return !!verifiedEmail(user);
}

export const client_app = initializeApp(firebaseConfig);
if (IS_DEV) {
    console.warn('*******************')
    console.warn('*******************')
    console.warn('*******************')
    console.warn('')
    console.warn('')
    console.warn('Running in dev mode')
    console.warn('')
    console.warn('')
    console.warn('*******************')
    console.warn('*******************')
    console.warn('*******************')
} else {
    console.info('This is a production build')
}
console.debug("App initialized");

export function cleanProperties(obj: any, allowedProperties: string[]): any {
    const newObj: any = {};
    for (const prop of allowedProperties) {
        if (obj[prop]) {
            newObj[prop] = obj[prop];
        }
    }
    return newObj;
}

export { GoogleAuthProvider };