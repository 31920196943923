import React, {FC, useState} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton, InputAdornment, TextField, TextFieldProps} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useSimpleI18n} from "../../utils/i18n";

export type PasswordFieldProps = TextFieldProps & {
}

const PasswordField: FC<PasswordFieldProps> = ({ label = 'Password', ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const i18n = useSimpleI18n()

    // add an effect, so that whenever password field is focused, we show password
    // and when it is blurred, we hide it

    return (
        <TextField
            {...props}
            autoComplete={'off'}
            onChange={(e) => {
                const password = e.target.value;
                if (props.onChange) {
                    props.onChange(e);
                }
                // if there is a password, and we have focus, show it
                if (e.target === document.activeElement) {
                    setShowPassword(!!password)
                } else {
                    setShowPassword(!password);
                }
            }
            }
            type={(!props.value || showPassword) ? 'text' : 'password'}

            onFocus={() => setShowPassword(true)}
            onBlur={() => setShowPassword(false)}
            label={i18n(label+"")}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={i18n("toggle password visibility")}
                            onClick={togglePasswordVisibility}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        <IconButton
                            aria-label={i18n('clear password')}
                            onClick={() => props.onChange && props.onChange({ target: { value: '' } } as any)}
                            edge="end">
                            <ClearIcon />
                            </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordField;
