import React, {useContext, useEffect, useRef, useState} from "react";
import {IntlProvider} from "react-intl";
import {EsApiContext} from "./utils/equi-scrib-internal-api-context";
import {SUPPORTED_LOCALES} from "./shared/utils/i18n";
import {SnackbarProvider} from "./components/globalsnackbar";
import AppAugmentedIJoyRide from "./AppAugmentedJoyRide";

const AppAugmentedI18N = () => {
    const [locale, setLocale] = useState(navigator.language?.substring(0, 2)); // Default to the browser language
    const [messages, setMessages] = useState({});
    const localeSet = useRef(undefined as string|undefined);
    const esApi = useContext(EsApiContext);
    const [messagesSet, setMessagesSet] = useState(false);

    esApi.onLanguageChanged((l) => {
        if (!SUPPORTED_LOCALES.includes(l)) {
            console.error("Unsupported locale: " + l);
            return;
        }
        setLocale(l)
    });

    useEffect(() => {
        if (localeSet.current === locale) {
            return;
        }
        localeSet.current = locale;
        esApi.fetchMessages(locale)
            .then((msg) => {
                setMessages(msg);
                setMessagesSet(true);
                console.log("Language changed to " + locale);
            })
            .catch((_e) => {
                setMessagesSet(false);
                localeSet.current = undefined
            })
    }, [esApi, locale]);

    return (
            <IntlProvider locale={locale} messages={messages}>
                <SnackbarProvider>
                {messagesSet && <>
                    <AppAugmentedIJoyRide/>
                </>}
                </SnackbarProvider>
            </IntlProvider>
    );
};

export default AppAugmentedI18N