import {useParams} from "react-router-dom";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ErrorIcon from '@mui/icons-material/Error';
import {useContext, useEffect, useRef, useState} from "react";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {AlternateImage, generateReflectionId, MAX_ALTERNATIVES, seHasInput, seText} from "../../shared/types";
import styles from './styles.module.css'
import {SimpleI18NMessage, useSimpleI18n} from "../../utils/i18n";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";

const AlternateImages = () => {
    const dateStr = useParams().date_and_seq || '';
    const esApi = useContext(EsApiContext)

    const [loadingStatus, setLoadingStatus] = useState("Loading...");
    const [imagePrompt, setImagePrompt] = useState("");
    const isStartingUp = useRef(false);
    const [imageUrl, setImageUrl] = useState("");
    const [error, setError] = useState("");


    const [alts, setAlts] = useState([] as AlternateImage[]);
    const altsRef = useRef(alts);
    const [reflectionKey, setReflectionKey] = useState('')

    const requests = useRef([] as AbortController[]);

    async function startup() {
        if (isStartingUp.current) {
            return;
        }
        isStartingUp.current = true;
        setLoadingStatus("Logging in...");
        await esApi.login()
        setLoadingStatus("Getting quill entry...");
        const se = (await esApi.getScribEntries(dateStr))[0];
        if (!se)
            throw new Error("No entries found for " + dateStr)
        if (!seHasInput(se))
            throw new Error("No input found for " + dateStr)
        setLoadingStatus("Getting current reflection...");
        const reflection = await esApi.getReflection(generateReflectionId(seText(se)))
        if (!reflection || !reflection.imageAltText) {
            throw new Error("No reflection found for " + dateStr)
        }
        setLoadingStatus("DONE");
        setReflectionKey(reflection.id);
        setImagePrompt(reflection.imageAltText);
        setImageUrl(reflection.image)
        refreshAll4(reflection.imageAltText);
    }

    function cancelAllRequests() {
        requests.current.forEach(r => r.abort());
        requests.current = [];
    }

    function clearAllImages() {
        const images: AlternateImage[] = [];
        for (let i = 0; i < 16; i++) {
            images.push({
                url: '',
                style: 'waiting',
                created: 0,
            })
        }
        setAlts(images);
        altsRef.current = images;
    }

    function processing(i: number) {
        const images = [...altsRef.current];
        images[i] = {
            url: '',
            style: 'processing',
            created: 0,
        };
        setAlts(images);
        altsRef.current = images;
    }

    function processed(number: number, img1: AlternateImage) {
        const images = [...altsRef.current];
        images[number] = img1;
        setAlts(images);
        altsRef.current = images;
    }

    async function generateAlternatives(prompt: string, model: string, startAt: number) {
        const cancelToken = esApi.getCancelToken();
        let lastImageUrl = ''
        for (let i = 0; i < MAX_ALTERNATIVES; i++) {
            processing(i + startAt);
            requests.current.push(cancelToken);
            try {
                const img1 = await esApi.createReflectionAlternative(prompt, model, lastImageUrl, cancelToken);
                lastImageUrl = img1.url;
                img1.style = 'processed'
                processed(i + startAt, img1);
            } catch (e:any) {
                if (e.name === 'AbortError') {
                    // ignore
                } else {
                    processed(i + startAt, {
                        url: '',
                        style: 'error',
                        created: 0,
                        source: e.message,
                        cost: 0
                    });
                }
            }
        }
    }

    function refreshAll4(imagePrompt: string) {
        clearAllImages();
        cancelAllRequests();
        setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            generateAlternatives(imagePrompt, 'dall-e-3', 0);
            // noinspection JSIgnoredPromiseFromCall
            generateAlternatives(imagePrompt, 'dall-e-2', 4);
            generateAlternatives(imagePrompt, 'google', 8);
            // noinspection JSIgnoredPromiseFromCall
            generateAlternatives(imagePrompt, 'Leonardo-C', 12);
            // noinspection JSIgnoredPromiseFromCall
            generateAlternatives(imagePrompt, 'Leonardo-E', 16);
        }, 1000);
    }

    const i18n = useSimpleI18n();

    useEffect(() => {
        startup().catch((e) => {setError(`${e}`)})
    }, [startup])

    function updateImagePrompt(prompt: string) {
        setImagePrompt(prompt)
    }

    if (error) {
        return <div>{error}</div>
    }

    async function setReflectionKeyImage(url: string) {
        await esApi.setPrimaryImage(reflectionKey, imagePrompt, url);
        window.location.reload()
    }


    return (
        <div className={styles.main}>
            <h1>{i18n("Alternate Images")}</h1>
            <div className={styles.prompt}>
                <TextField rows={5} fullWidth={true} multiline={true} value={imagePrompt} onChange={(e) => updateImagePrompt(e.target.value)}></TextField>
                {/*<textarea rows={5} value={imagePrompt} onChange={(e) => updateImagePrompt(e.target.value)}></textarea>*/}
                <Button variant={'contained'} onClick={() => refreshAll4(imagePrompt)}>{i18n("GO")}</Button>
            </div>
            <div className={styles.images}>
                <div className={styles.imageWrapper + ' selected'}>
                    <img src={imageUrl} alt="primary"/>
                </div>
                {alts.map((alt, idx) => (
                    <div key={'img' + idx} className={styles.imageWrapper + ' '  + alt.style} onClick={() => setReflectionKeyImage(alt.url)}>
                        {alt.style === "waiting" && <div className={styles.noImageDiv + ' waiting'}><HourglassEmptyIcon/></div>}
                        {alt.style === "processing" && <div className={styles.noImageDiv + ' processing'}><HourglassTopIcon/></div>}
                        {alt.style === "error" && <div className={styles.noImageDiv + ' error'}><ErrorIcon/></div>}
                        {alt.style === "processed" && <img src={alt.url}/>}
                        <div className={styles.labels}>
                        <div><span>{i18n("Cost:")}</span>{alt.cost}</div>
                        <div><span>{i18n("Source:")}</span>{alt.source}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.status}><SimpleI18NMessage msg={loadingStatus}/></div>
        </div>
    )
}

export default AlternateImages;