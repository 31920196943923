import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import React, {useContext, useEffect, useState} from "react";
import styles from './App.module.css';
import LoginPage from './components/login';
import SettingsPage, {settingsPages} from "./components/settings";
import Dayview from "./components/dayview";
import {EsApiContext} from "./utils/equi-scrib-internal-api-context";
import LogoutPage from "./components/logout";
import PublicMdPage from "./components/publicmd";
import DayOrMonthView from "./components/daysormonthview";
import {ErrorBoundary} from "react-error-boundary";
import MenuIcon from '@mui/icons-material/Menu';
import AlternateImages from "./components/atlernateimages";
import About from "./components/about";
import {useSimpleI18n} from "./utils/i18n";
import {Menu, MenuItem} from "@mui/material";
import {TUT_7_CLASSNAME} from "./components/tutorial";
import SharedEntry from "./components/shareentry";


function App() {

    const navigateTmp = useNavigate();
    const navigate2 = (d: string) => {
        window.location.href = 'https://www.equiquill.com/' + d;
    }
    const navigate = (d: string) => {
        navigateTmp(d);
        setAnchorEl(null);
    }
    const location = useLocation()

    const esApi = useContext(EsApiContext);

    const [isLoggedIn, setIsLoggedIn] = useState(esApi.isLoggedIn);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 500);
    };


    useEffect(() => {
        (async () => {
            // if not on login path, check if logged in
            if (['/privacy', '/support', '/about', '/contact'].includes(location.pathname)) {
                return;
            }
            // not sure why this location shows up.  Just go to root if it does
            if (location.pathname === '/_=_') {
                navigate('/')
            }
            const sharedEntryRe = /^\/sharedentries\/[a-zA-Z0-9]{40}$/;
            const isSharedEntry = sharedEntryRe.test(location.pathname);
            if (location.pathname !== '/login' && !isLoggedIn && !isSharedEntry) {
                console.log("Not on login or sharedentry location, checking if logged in");
                esApi.login()
                    .then(() => {
                        console.log("Confirmed login");
                        setIsLoggedIn(true);
                    })
                    .catch((e) => {
                        console.log("NOT logged in " + e);
                        setIsLoggedIn(false);
                        navigate('/login');
                    })
            }
            if (esApi.isLoggedIn && location.pathname !== '/settings/security' && await esApi.isMissingPassword()) {
                navigate('/settings/security')
            }
            if (location.pathname === '/login' && isLoggedIn) {
                setIsLoggedIn(false)
            }
            if (location.pathname === '/' && isLoggedIn) {
                navigate('/escal')
            }
        })()
    }, [esApi, navigate, location.pathname, isLoggedIn]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            setAnchorEl(null)
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function createLink2(d: string, n: string) {
        return createLink(d, n, navigate2);
    }

    function createLink(route: string, idx: string, navigateFn: (d: string) => void = navigate, isTut7: boolean = false) {
        return (
            <li className={
                (
                    ('' === route ? styles.selMenu : '') +
                    (isTut7 ? ' ' + TUT_7_CLASSNAME : '')
                ).trim()
            }
                onClick={() => {
                    navigateFn(route);
                }}
                key={route}>{idx}</li>
        );
    }

    const i18n = useSimpleI18n();

    return (
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <div className={styles.container}>
                <header className={styles.header}>
                    <img src={'/equiscrib.png'} alt={i18n('EquiQuill Logo')} width={'64px'}/>
                    <h1 onClick={() => window.location.href = 'https://www.equiquill.com'}>
                        EquiQuill
                    </h1>
                    <div className='spacer'/>
                    {isMobile && isLoggedIn && (
                        <div className={styles.hamburgerMenu + ' ' + TUT_7_CLASSNAME}>
                            <MenuIcon onClick={
                                (el) => {
                                    setAnchorEl(el.currentTarget as any)
                                }
                            }/>
                            <Menu anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={() => setAnchorEl(null)}
                            >
                                <MenuItem onClick={() => navigate('escal')}>{i18n('Home')}</MenuItem>
                                <MenuItem onClick={() => navigate('settings')}>{i18n('Settings')}</MenuItem>
                                <MenuItem onClick={() => navigate2('about')}>{i18n('About')}</MenuItem>
                                <MenuItem onClick={() => navigate2('tandc')}>{i18n('T&Cs')}</MenuItem>
                                <MenuItem onClick={() => navigate2('privacy')}>{i18n('Privacy')}</MenuItem>
                                <MenuItem onClick={() => navigate2('contact-us')}>{i18n('Contact')}</MenuItem>
                                <MenuItem onClick={() => navigate('logout')}>{i18n('Logout')}</MenuItem>
                            </Menu>
                        </div>
                    )}
                    {(!isMobile || !isLoggedIn) && (
                        <nav className={isMobile && isLoggedIn ? styles.popupMenu : ''}>
                            <ul>
                                {isLoggedIn && createLink('escal', i18n('Home'))}
                                {/*{createLink('analysis', 'Analysis')}*/}
                                {isLoggedIn && createLink('settings', i18n('Settings'), navigate, true)}
                                {!isLoggedIn && createLink('login', i18n('Login'))}
                                {isLoggedIn && createLink('logout', i18n('Logout'))}
                            </ul>
                        </nav>
                    )}
                </header>

                <main className={styles.main}>
                <Routes>
                        <Route path='/' element={<div/>}/>
                        <Route path='/login' element={<LoginPage/>}/>
                        <Route path='/logout' element={<LogoutPage/>}/>
                        <Route path='/sharedentries/:id' element={<SharedEntry/>}/>
                        <Route path='/escal' element={<DayOrMonthView/>}/>
                        <Route path='/settings' element={<SettingsPage/>}>
                            {settingsPages.map((page) => {
                                return (
                                    <Route key={page.subPage} path={page.subPage} element={page.component()}/>
                                )
                            })}
                        </Route>
                        <Route path='/dayview/:date_and_seq' element={<Dayview/>}/>
                        <Route path='/dayview/:date_and_seq/alternatives' element={<AlternateImages/>}/>
                        <Route path='/privacy' element={<PublicMdPage key='privacy.md' page={'privacy.md'}/>}/>
                        <Route path='/contact' element={<PublicMdPage key='contact.md' page={'contact.md'}/>}/>
                        <Route path='/tandc' element={<PublicMdPage key='tAndC.md' page={'tAndC.md'}/>}/>
                        <Route path='/about' element={<About/>}/>
                    </Routes>
                </main>

                <footer className={styles.footer}>
                    <p>&copy; 2024 EquiQuill</p>
                    {!isMobile && (<ul>
                        {createLink2('about', i18n('About'))}
                        {createLink2('tandc', i18n('T&Cs'))}
                        {createLink2('privacy', i18n('Privacy'))}
                        {createLink2('contact', i18n('Contact'))}
                    </ul>)}
                </footer>

            </div>
        </ErrorBoundary>
    );
}

export default App;
