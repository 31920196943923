import React, {useContext, useEffect, useState} from "react";
import styles from './styles.module.css'
import {EsApiContext} from "../../../utils/equi-scrib-internal-api-context";
import {Button} from "@mui/material";

const IntegrationsScreen = () => {
    const esApi = useContext(EsApiContext);
    const [stravaState, setStravaState] = useState('loading')
    const [isWorking, setIsWorking] = useState(false)

    function authorizeStrava() {
        setIsWorking(true)
        // Parameters for the Strava OAuth authorization URL
        const clientId = '4782'; // Replace with your Strava app's client ID
        const redirectUri = encodeURIComponent('https://app.equiquill.com/api/strava'); // Replace with your redirect URI
        const responseType = 'code';
        const scope = 'activity:read_all,activity:write'; // Adjust scopes as needed
        const state = esApi.esAuth

        // Construct the full authorization URL
        const authUrl = `https://www.strava.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${state}`;

        // Open the authorization URL in a new window
        const openedWindow = window.open(authUrl, '_blank');
        // wait for authorization flow to complete, and then refresh settings.
        const interval = setInterval(() => {
            if (openedWindow?.closed) {
                clearInterval(interval);
                // refresh by simply reloading the page
                window.location.reload();
            }
        }, 1000);
    }

    async function startLoadingAsync() {
        setStravaState('loading');
        const settings = await esApi.getSettings();
        setStravaState(settings?.stravaInfo?.athleteId ? 'active' : 'inactive');
    }

    useEffect(() => {
        startLoadingAsync()
    }, []);

    async function deauthorizeStrava() {
        setIsWorking(true)
        await esApi.deauthorizeStrava()
        window.location.reload();
    }

    return (
        <div className={styles.main}>
            <h2>Strava Integration</h2>
            <p>Automatically generate an image that summarizes your day, and attach it to your Strava activity</p>
            {stravaState === 'active' && (
                <div className={styles.deactivateButton}>
                    <p>Strava integration is active</p>
                    <Button onClick={deauthorizeStrava} color={"primary"} variant={"contained"}
                            disabled={isWorking}>DEACTIVATE</Button>
                </div>
            )}
            {stravaState === 'inactive' &&
                <button onClick={authorizeStrava} disabled={isWorking}><img src='/btn_strava_connectwith_orange.svg'/>
                </button>}
            {stravaState === 'loading' && <p>Checking Strava Integration State...</p>}
        </div>
    )
}

export default IntegrationsScreen;