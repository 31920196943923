import styles from './styles.module.css';
import IconButton from "@mui/material/IconButton";
import {MAX_ENTRIES_PER_DAY, ScribEntry, seEmoji, seHasInput, seHover} from "../../shared/types";
import Fullhoverable from "../fullhoverable";

export type EntrysummarybuttonsProps = {
    entries: Array<ScribEntry>
    currentEntry: number
    onEntryChange: (entry: number) => void
}

export const EntrySummaryButtons = (props: EntrysummarybuttonsProps) => {
    const entries = props.entries;
    if (!Array.isArray(entries)) {
        throw new Error("Expected entries to be an array")
    }

    const canAddEntry = entries.length < MAX_ENTRIES_PER_DAY && entries.every(seHasInput);

    function setEntry(n: number) {
        if (n === props.currentEntry) {
            return;
        }
        if (props.onEntryChange) {
            props.onEntryChange(n);
        }
    }

    return (
        <div className={styles.entrySummaryButtons}>
            {
                canAddEntry && (<IconButton className={styles.emptyEntry} onClick={() => setEntry(entries.length)} title='+'>+</IconButton>)
            }
            {entries.slice().reverse().map((entry, i) => {
                const oi = entries.length - i - 1;
                return (
                    <Fullhoverable text={seHover(entry)} key={`fullhoverable${oi}`}>
                        <IconButton
                            onClick={() => setEntry(oi)}
                            key={`emoji${oi}`}
                            className={
                                (props.currentEntry === oi ? styles.selectedEmoji : '')
                            }>
                            {seEmoji(entry)||'?'}
                    </IconButton>
                    </Fullhoverable>
            )})}
        </div>
    );

}