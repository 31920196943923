import {AES, enc} from "crypto-js";
import { Buffer} from "buffer";

export type EncryptionParameters  = {
    pseudoE2eKey: string,
    pseudoE2eKeyVersion: number,
    trueE2eKey?: string,
    trueE2eKeyVersion?: number
}

export const UNENCRYPTED_KEY_PARAMETERS = {
    pseudoE2eKey:  'unencrypted',
    pseudoE2eKeyVersion: 0,
    trueE2eKey: 'unencrypted',
    trueE2eKeyVersion: 0
}


export function verifyKeys(serverPassword: string, serverKeyVersion: number, clientPassword: string|undefined, clientKeyVersion: number|undefined) {
    if (serverPassword === 'unencrypted' && serverKeyVersion === 0 && clientPassword === 'unencrypted' && clientKeyVersion === 0) {
        return;
    }
    if (!serverPassword) {
        throw new Error('serverPassword must be defined');
    }
    if (!serverKeyVersion) {
        throw new Error('serverKeyVersion must be defined');
    }
    if (clientPassword && !clientKeyVersion) {
        throw new Error('clientKeyVersion must be defined if clientPassword is defined');
    }
    if (clientKeyVersion && !clientPassword) {
        throw new Error('clientPassword must be defined if clientKeyVersion is defined');
    }
}

function iisNoEncryption(eps: EncryptionParameters) {
    return eps.pseudoE2eKey === 'unencrypted' && eps.trueE2eKey === 'unencrypted' &&
        eps.pseudoE2eKeyVersion === 0 && eps.trueE2eKeyVersion === 0;
}

export function encryptString(text: string, eps: EncryptionParameters): string {
    if (iisNoEncryption(eps)) {
        if (!text) {
            return text;
        }
        // convert text to base 64
        const b64Text = Buffer.from(text).toString('base64');
        return `e2eS0C0 ${b64Text}`
    }
    verifyKeys(eps.pseudoE2eKey, eps.pseudoE2eKeyVersion, eps.trueE2eKey, eps.trueE2eKeyVersion);
    if (!text) {
        return text;
    }
    const header = calculateEncryptedStringHeading(eps.pseudoE2eKeyVersion, eps.trueE2eKeyVersion);
    let encrypted = AES.encrypt(text, eps.pseudoE2eKey).toString();
    if (eps.trueE2eKeyVersion) {
        encrypted = AES.encrypt(encrypted, eps.trueE2eKey!).toString();
    }
    return header + encrypted;
}

export function isEncrypted(text: string): boolean {
    if (!text || !text.length) {
        return false;
    }
    if (!text.startsWith('e2eS')) {
        return false;
    }
    if (text.startsWith('e2eS0C0 ')) {
        return false;
    }
    // find first space after e2eS (but no more than 10 characters in );
    const spaceIndex = text.indexOf(' ', 4);
    if (spaceIndex < 0 || spaceIndex > 15) {
        return false;
    }
    const encryptedString = text.substring(spaceIndex + 1);
    // check string is b64 encoded
    if (encryptedString.length % 4 !== 0) {
        return false;
    }
    if (/[^A-Za-z0-9+/=]/.test(encryptedString)) {
        return false;
    }
    // must be at least 20 characters
    return encryptedString.length >= 20;
}

export function decryptString(text: string, eps: EncryptionParameters): string {
    if (!text) {
        return text;
    }
    if (text.startsWith('e2eS0C0 ')) {
        return Buffer.from(text.substring(8), 'base64').toString();
    }
    verifyKeys(eps.pseudoE2eKey, eps.pseudoE2eKeyVersion, eps.trueE2eKey, eps.trueE2eKeyVersion);
    const expectedHeader = calculateEncryptedStringHeading(eps.pseudoE2eKeyVersion, eps.trueE2eKeyVersion);
    const actualHeader = text.substring(0, expectedHeader.length);
    if (actualHeader !== expectedHeader) {
        throw new Error(`Header mismatch: encrypted string header ${actualHeader} does not match expected header ${expectedHeader}`);
    }
    text = text.substring(expectedHeader.length);
    if (eps.trueE2eKeyVersion) {
        text = AES.decrypt(text, eps.trueE2eKey!).toString(enc.Utf8);
    }
    return AES.decrypt(text, eps.pseudoE2eKey).toString(enc.Utf8);
}

export function calculateEncryptedStringHeading(serverKeyVersion: number, clientKeyVersion: number|undefined) {
    if (serverKeyVersion === 0 && clientKeyVersion === 0) {
        return `e2eS${serverKeyVersion}C${clientKeyVersion||0} `;
    }
    if (!serverKeyVersion) {
        throw new Error('serverKeyVersion must be defined');
    }
    return `e2eS${serverKeyVersion}C${clientKeyVersion||0} `;
}