import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import styles from "./styles.module.css";
import {WidthHeight} from "../dynamicfiller";
import React, {ReactNode, useRef, useState} from "react";
import {ReflectContent, ReflectContentProps} from "./reflectcontent";


export interface ReflectDialogProps {
    onRefresh?: () => void
    onProofs?: () => void
    onThumbsUp?: () => void
    onThumbsDown?: () => void
    onClose?: () => void
    scribEntryId: string;
    displayImage?: string|Error;
    displayText?: string|Error;
}

export const ReflectDialog = (props: ReflectDialogProps) => {
    return (
        <Dialog onClose={props.onClose} open={!!(props.displayText || props.displayImage)} maxWidth={'xl'}
                fullWidth={true}>
            <DialogContent className={styles.dialogMain}>
                <ReflectContent
                    onClick={props.onClose}
                    {...props}
                />
            </DialogContent>
        </Dialog>
    )
}