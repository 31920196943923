// display a modal MUI dialog that prompts the user to enter their current password
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import styles from "./styles.module.css";
import Button from "@mui/material/Button";
import {useContext, useEffect, useState} from "react";
import {EsApiContext} from "../../../utils/equi-scrib-internal-api-context";
import {calculateEncryptedStringHeading, decryptString, EncryptionParameters} from "../../../shared/utils/crypto";
import {UserSettings} from "../../../shared/types";
import LoadingOverlay from "../../loadingoverlay";
import {I18N, useSimpleI18n} from "../../../utils/i18n";

export type PromptProps = {
    missingPassword: boolean;
    missingNewPassword: boolean;
    knownSettings: UserSettings;
    onCurrentPasswordSet: (password: string) => void;
    onNewPasswordSet: (password: string) => void;
    onTurnOffE2e: () => void;
}

const PromptPassword = (props: PromptProps) => {
    const esApi = useContext(EsApiContext);
    const [isLoading, setIsLoading] = useState(true);
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [sampleValue1, setSampleValue1] = useState('')
    const [sampleValue2, setSampleValue2] = useState('')
    const [eps1, setEps1] = useState({} as EncryptionParameters)
    const [eps2, setEps2] = useState({} as EncryptionParameters)
    const [error1, setError1] = useState('')
    const [error2, setError2] = useState('')

    const i18n = useSimpleI18n()

    const title = props.missingPassword && props.missingNewPassword ? "Missing passwords" :
        props.missingPassword ? "Missing password" : "Missing new End-to-End password";

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (props.missingPassword) {
                const eps1 = calculateEncryptedStringHeading(props.knownSettings.pseudoE2eKeyVersion, props.knownSettings.trueE2eKeyVersion);
                setSampleValue1(await esApi.getSampleTextValueForKey(eps1));
                setEps1({
                    pseudoE2eKeyVersion: props.knownSettings.pseudoE2eKeyVersion,
                    pseudoE2eKey: props.knownSettings.pseudoE2eKey,
                    trueE2eKeyVersion: props.knownSettings.trueE2eKeyVersion
                } as EncryptionParameters);
            }
            if (props.missingNewPassword) {
                const eps2 = calculateEncryptedStringHeading(props.knownSettings.newPseudoE2eKeyVersion!, props.knownSettings.newTrueE2eKeyVersion);
                setSampleValue2(await esApi.getSampleTextValueForKey(eps2));
                setEps2({
                    pseudoE2eKeyVersion: props.knownSettings.newPseudoE2eKeyVersion!,
                    pseudoE2eKey: props.knownSettings.newPseudoE2eKey!,
                    trueE2eKeyVersion: props.knownSettings.newTrueE2eKeyVersion,
                } as EncryptionParameters);
            }
            setIsLoading(false);
        })()
    }, [esApi, props.knownSettings.newPseudoE2eKey, props.knownSettings.newPseudoE2eKeyVersion, props.knownSettings.newTrueE2eKeyVersion, props.knownSettings.pseudoE2eKey, props.knownSettings.pseudoE2eKeyVersion, props.knownSettings.trueE2eKeyVersion, props.missingNewPassword, props.missingPassword]);

    async function finishDialog() {
        let proceed = true;
        if (props.missingPassword && !canDecrypt(sampleValue1, password1, eps1)) {
            setError1(i18n('Invalid password'))
            proceed = false;
        }
        if (props.missingNewPassword && !canDecrypt(sampleValue2, password2, eps2)) {
            setError2(i18n('Invalid password'))
            proceed = false;
        }
        if (!proceed) {
            return;
        }

        if (props.missingPassword && props.onCurrentPasswordSet) {
            props.onCurrentPasswordSet(password1);
        }
        if (props.missingNewPassword && props.onNewPasswordSet) {
            props.onNewPasswordSet(password2);
        }
    }

    function canDecrypt(sampleValue: string, password: string, eps: EncryptionParameters) {
        if (!sampleValue) {
            return false
        }
        if (!password) {
            return true
        }
        try {
            decryptString(sampleValue, {...eps, trueE2eKey: password})
            return true
        } catch (e: any) {
            return false
        }
    }

    return (
        <Dialog open={props.missingPassword || props.missingNewPassword}>
            <DialogTitle>{i18n(title)}</DialogTitle>
            <Box component="form" onSubmit={finishDialog} noValidate autoComplete="off">
                <DialogContent>
                    {props.missingPassword && <>
                        <p><I18N>Could not find your end-to-end password in your browser's local storage. Please enter your
                            current password to continue.</I18N></p>
                        <TextField className={error1 ? styles.shake : ''}
                                   id={"current-password"}
                                   autoFocus
                                   error={!!error1}
                                   helperText={error1}
                                   margin="dense"
                                   label={i18n('Password')}
                                   value={password1}
                                   onChange={(e) => {
                                       setError1('')
                                       setPassword1(e.target.value)
                                   }}
                                   fullWidth
                        />
                    </>}
                    {props.missingNewPassword && <>
                        <p><I18N>Some of your data has been converted using a new end-to-end password. This password can no
                            longer
                            be located in your browser's localstorage. Please re-enter this password to continue.</I18N></p>
                        <TextField className={error2 ? styles.shake : ''}
                                   id={"new-password"}
                                   error={!!error2}
                                   helperText={error2}
                                   autoFocus
                                   margin="dense"
                                   label={i18n('Password')}
                                   value={password2}
                                   onChange={(e) => {
                                       setError2('')
                                       setPassword2(e.target.value)
                                   }}
                                   fullWidth
                        />
                    </>}
                    {props.missingPassword && props.missingNewPassword && <>
                        <p><I18N>You are likely seeing this screen from having configured a new password for your end-to-end
                            encryption,
                            from a different browser or device. Your best option to avoid this error, is to go
                            back to the browser or device and let the conversion complete.
                        </I18N></p>
                        <p><I18N>If this is not an option for you, or if your browser's cache has been accidentally cleared,
                            then
                            your best option will be to guess your old and new passwords, until you find a password that
                            works.
                        </I18N></p>
                        <p><I18N>Without these passwords, your entries will not be accessible</I18N></p>
                    </>}
                    {props.missingNewPassword && !props.missingPassword && <>
                        <p><I18N>You are likely seeing this screen from having activated end-to-end encryption
                            from a different browser or device. Your best option to avoid this error, is to go
                            back to the browser or device and let the conversion complete.
                        </I18N></p>
                        <p><I18N>If this is not an option for you, or if your browser's cache has been accidentally cleared,
                            then
                            your best option will be to guess your end-to-end encryption password, until you find a
                            password that works.
                        </I18N></p>
                        <p><I18N>Without this password, some of your entries will not be accessible</I18N></p>
                    </>}
                    {props.missingPassword && !props.missingNewPassword && <>
                        <p><I18N>You are likely seeing this screen from having configured a password for your end-to-end
                            encryption, from a different browser or device. When using end-to-end encryption, you must
                            manually set a password on each browser or device that you use. You can lookup your password
                            by going to the settings page on the browser or device that you used to set the password.
                        </I18N></p>
                        {!isLoading && <>
                            {sampleValue1 &&
                                <p><I18N>If this is not an option for you, or if your browser's cache has been accidentally
                                    cleared, then your best option will be to guess your password, until you find a
                                    password
                                    that works.
                                </I18N></p>}
                            {!sampleValue1 &&
                                <p><I18N>It looks like you don't have any entries encrypted with end-to-end encryption, so
                                    your
                                    best option may be to revert back to pseudo end-to-end encryption.
                                </I18N></p>}
                        </>}
                    </>}
                    <DialogActions>
                        <Button type="submit" disabled={isLoading || (
                            (!props.missingPassword ? false : !password1) ||
                            (!props.missingNewPassword ? false : !password2)
                        )} onClick={finishDialog} color="primary">Update</Button>
                        {!isLoading && !sampleValue1 && props.missingPassword && <Button onClick={() => {
                            props.onTurnOffE2e?.();
                        }} color="primary">Turn Off E2e Encryption</Button>}
                    </DialogActions>
                </DialogContent>
                {isLoading && <LoadingOverlay/>}
            </Box>
        </Dialog>
    )
}

export default PromptPassword;