import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {HashRouter} from 'react-router-dom';
import {EquiScribInternalApi} from "./shared/utils/equi-scrib-internal-api";
import {EsApiContext} from "./utils/equi-scrib-internal-api-context";
import AppAugmentedI18N from "./AppAugmentedI18N";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const esApi = new EquiScribInternalApi()

root.render(
    <React.StrictMode>
        <EsApiContext.Provider value={esApi}>
            <HashRouter basename={'/'}>
                <AppAugmentedI18N/>
            </HashRouter>
        </EsApiContext.Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
